<template>
  <ion-page>
    <ion-content>
      <ion-grid :fixed="true" class="ion-padding">
        <ion-row>
          <ion-col size-md="12" size-xs="12">
            <ion-card color="light" class="center-card">
              <img src="../../../public/img/icons/gardxgroup/Tick.svg" />
              <ion-card-header>
                <ion-card-title
                  >Thank you for submitting your request.</ion-card-title
                >
              </ion-card-header>

              <ion-card-content>
                We will review the extent of the damage which will take
                approximately two business days.<br />
                <br />
                If we are unable to validate your request based on the
                supporting material provided, we may need to get back in touch
                for additional information.<br />
                <br />
                To keep track of your request status, you can visit
                <a href="/requests">Requests</a> anytime, anywhere.
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-end">
          <ion-col size-md="4" size-xs="12">
            <ThankYouCards
              title="Damaged in more than one area? No problem."
              content="Submit another request to ensure your vehicle is fully repaired."
              button-label="Submit another vehicle"
              path="/submit-request"
            />
          </ion-col>
          <ion-col size-md="4" size-xs="12">
            <ThankYouCards
              title="Enjoyed your experience?"
              content="We value your feedback. Help us improve by sharing your experience using your GardX portal."
              button-label="Leave feedback"
              path=""
            />
          </ion-col>
          <ion-col size-md="4" size-xs="12">
            <ThankYouCards
              title="Looking for additional coverage?"
              content="Whatever you need, we've got you covered. Select a product and a member of the team will be in contact."
              button-label="Select a product"
              path="/products"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/vue';
import { ref, onMounted, onUnmounted } from 'vue';
import ThankYouCards from '@/components/ThankYouCards.vue';
import FooterComponent from '@/components/FooterComponent.vue';

const screenWidth = ref(window.innerWidth);
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

img {
  max-width: 10%;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }

  img {
    max-width: 40%;
  }
}

.center-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  ion-icon {
    font-size: 160px;
  }

  ion-card-title {
    font-size: 24px;
  }

  a {
    color: #252539;
    text-decoration: none;
    font-weight: bold;
  }
}
</style>
