<template>
  <footer class="main-footer">
    <div class="container">
      <div class="row">
        <div class="footer-col">
          <img :src="logoSrc" />
        </div>
        <div class="footer-col">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/dashboard">Dashboard</a></li>
            <li><a href="/products">Products</a></li>
            <li><a href="/submit-request">Submit A Request</a></li>
            <li><a href="/requests">Requests</a></li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Support</h4>
          <ul>
            <li><a href="/support/faqs">FAQs</a></li>
            <li><a href="/support/video-support">Video Support</a></li>
            <li><a href="/support/get-in-touch">Speak To Us</a></li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Policies</h4>
          <ul>
            <li><a :href="privacyPolicyLink">Privacy Policy</a></li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Other</h4>
          <ul>
            <li><a :href="termsConditionsLink">Terms & Conditions</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  <footer class="sub-footer">
    <div class="container">
      <div class="row">
        <p>
          {{ footer }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue';
import { useBrandingStore } from '../store/branding';

const logoSrc = ref('');
const footer = ref('');
const privacyPolicyLink = ref('');
const termsConditionsLink = ref('');
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;

logoSrc.value = brandingStore.brand.logo_footer;
footer.value = brandingStore.brand.footer;
privacyPolicyLink.value = brandingStore.brand.policy;
termsConditionsLink.value = brandingStore.brand.terms_conditions;
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1170px;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

ul {
  list-style: none;
}

li {
  padding-top: 5px;
}

.main-footer {
  background-color: v-bind('branding.color_secondary');
  padding: 30px 0;
}

.sub-footer {
  background-color: v-bind('branding.sub_footer_color');
  padding: 10px 0;

  .row {
    justify-content: center;
    text-align: center;
  }

  p {
    color: #ffffff;
    font-size: 12px;
  }
}

.footer-col {
  width: 20%;
  padding: 0 30px;

  h4 {
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
    color: #ffffff;
  }

  img {
    width: 160px;
    transition: 0.5s ease all;
  }
}

.footer-col ul li a {
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  display: block;

  &:hover {
    color: #ef7e31;
  }
}

@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}
</style>
