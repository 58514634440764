<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="SUBMIT A REQUEST."
          description="Select your product below and our step-by-step process will guide you through each stage of your request, with clear instructions and helpful support."
        />
      </div>
      <ion-grid :fixed="true" class="ion-padding">
        <div>
          <h2>Select product below to begin your request:</h2>
        </div>
        <ion-row v-for="policy in policies" :key="policy.id">
          <ion-col class="ion-padding-top ion-padding-bottom">
            <SubmitRequestCard :policy="policy" />
          </ion-col>
        </ion-row>
      </ion-grid>
      <div
        id="contact-container"
        :style="{ 'background-color': branding.contact_container_color }"
      >
        <ion-grid :fixed="true" class="ion-padding">
          <ContactCard
            :getInTouchButton="true"
            :seeFAQ="true"
            title="Can't find what you're looking for?"
            description="Please check out our FAQs or get in touch with the team."
          />
        </ion-grid>
      </div>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/vue';
import BannerComponent from '@/components/BannerComponent.vue';
import SubmitRequestCard from '@/components/SubmitRequestCard.vue';
import ContactCard from '@/components/ContactCard.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useCustomerStore } from '../store/customer';
import { useBrandingStore } from '../store/branding';

const desktopBannerSrc = ref(
  require('../../public/assets/gardxgroup/banners/desktop_banners/Submit_Request.png')
);
const mobileBannerSrc = ref(
  require('../../public/assets/gardxgroup/banners/mobile_banners/Submit_Request_Mobile.png')
);
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const customerStore = useCustomerStore();
const policies = computed(() => {
  return customerStore.policies;
});
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}
</style>
