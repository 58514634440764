<template>
  <ion-card :style="{ background: branding.contact_container_color }">
    <ion-card-content>
      <ion-grid>
        <ion-row class="ion-justify-content-between">
          <ion-col size-md="6" size-xs="12">
            <ion-item
              lines="none"
              :style="{ '--background': branding.contact_container_color }"
            >
              <ion-label class="ion-text-wrap">
                <h2>{{ title }}</h2>
                <p>{{ description }}</p>
              </ion-label>
            </ion-item>
          </ion-col>
          <ion-col
            class="ion-align-self-center"
            v-show="getInTouchButton"
            size-md="2"
            size-xs="12"
          >
            <ion-button
              :style="{ '--background': branding.color_secondary }"
              href="/support/get-in-touch"
              expand="full"
            >
              Get in touch
            </ion-button>
          </ion-col>
          <ion-col
            class="ion-align-self-center"
            v-show="seeFAQ"
            size-md="2"
            size-xs="12"
          >
            <ion-button
              href="/support/faqs"
              expand="full"
              :style="{ '--background': branding.color_primary }"
            >
              See our FAQs
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonButton,
} from '@ionic/vue';
import { defineProps } from 'vue';
import { useBrandingStore } from '../store/branding';

defineProps({
  title: String,
  description: String,
  getInTouchButton: Boolean,
  seeFAQ: Boolean,
});

const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
</script>

<style lang="scss" scoped>
ion-card {
  margin: 0 auto;
  box-shadow: none;

  h2 {
    font-size: 22px;
    font-weight: bold;
  }

  p {
    color: #252539;
  }
}
</style>
