<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="SUPPORT."
          description="Browse our FAQ section and video support guides for quick answers.
            If you need further assistance, get in touch."
        />
      </div>
      <ion-grid :fixed="true" class="ion-padding">
        <ion-row>
          <ion-col size-md="4" size-sm="6" size-xs="12">
            <SupportCards
              :icon="faqIcon"
              title="FAQs"
              content="Find quick answers to your questions."
              button-label="See our FAQs"
              path="/support/faqs"
            />
          </ion-col>
          <ion-col size-md="4" size-sm="6" size-xs="12">
            <SupportCards
              :icon="videoSupportIcon"
              title="Video Support"
              content="Helpful video guides to assist you with your queries."
              button-label="View"
              path="/support/video-support"
            />
          </ion-col>
          <ion-col size-md="4" size-sm="6" size-xs="12">
            <SupportCards
              :icon="getInTouchIcon"
              title="Get in touch"
              content="Need additional support? We're here to help."
              button-label="Chat to us"
              path="/support/get-in-touch"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/vue';
import BannerComponent from '@/components/BannerComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import SupportCards from '@/components/SupportCards.vue';
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useBrandingStore } from '../store/branding';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const faqIcon = ref(require('../../public/img/icons/gardxgroup/FAQ.svg'));
const videoSupportIcon = ref(
  require('../../public/img/icons/gardxgroup/Video_Support.svg')
);
const getInTouchIcon = ref(
  require('../../public/img/icons/gardxgroup/Get_In_Touch.svg')
);
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const brandingStore = useBrandingStore();

desktopBannerSrc.value = brandingStore.brand.desktop_banner_8;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_8;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}
</style>
