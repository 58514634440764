<template>
  <header :class="{ 'scrolled-nav': scrolledNav }">
    <nav>
      <div class="branding">
        <img :src="bannerSrc" />
      </div>
      <ul v-show="!mobile" class="navigation">
        <li>
          <router-link class="link" :to="{ name: 'dashboard' }"
            >Dashboard</router-link
          >
        </li>
        <li>
          <router-link class="link" :to="{ name: 'products' }"
            >Products</router-link
          >
        </li>
        <li>
          <router-link class="link" :to="{ name: 'submit-request' }"
            >Submit A Request</router-link
          >
        </li>
        <li>
          <router-link class="link" :to="{ name: 'requests' }"
            >Requests</router-link
          >
        </li>
        <li>
          <router-link class="link" :to="{ name: 'support' }"
            >Support</router-link
          >
        </li>
        <ion-button
          id="logout-trigger"
          :style="{ '--background': branding.color_primary }"
          >Log Out</ion-button
        >
        <ion-popover
          trigger="logout-trigger"
          trigger-action="click"
          side="bottom"
          alignment="center"
          :dismiss-on-select="true"
        >
          <ion-content
            class="ion-padding"
            :style="{ '--background': branding.color_secondary }"
          >
            <ion-row>
              <ion-col class="ion-text-center">
                <ion-label color="light">Log Out?</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="ion-text-center">
                <ion-button
                  id="logout-yes-btn"
                  @click="confirmLogout"
                  :style="{ '--background': branding.color_primary }"
                  >Yes</ion-button
                >
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="ion-text-center">
                <ion-button
                  id="logout-no-btn"
                  :style="{ '--background': branding.color_primary }"
                  >No</ion-button
                >
              </ion-col>
            </ion-row>
          </ion-content>
        </ion-popover>
      </ul>
      <div class="icon">
        <ion-icon
          @click="toggleMobileNav"
          v-show="mobile"
          :icon="menu"
          :class="{ 'icon-active': mobileNav }"
        ></ion-icon>
      </div>
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <ion-icon @click="toggleMobileNav" :icon="closeOutline"></ion-icon>
          <li>
            <router-link
              class="link"
              :to="{ name: 'dashboard' }"
              @click="toggleMobileNav"
              >Dashboard</router-link
            >
          </li>
          <li>
            <router-link
              class="link"
              :to="{ name: 'products' }"
              @click="toggleMobileNav"
              >Products</router-link
            >
          </li>
          <li>
            <router-link
              class="link"
              :to="{ name: 'submit-request' }"
              @click="toggleMobileNav"
              >Submit A Request</router-link
            >
          </li>
          <li>
            <router-link
              class="link"
              :to="{ name: 'requests' }"
              @click="toggleMobileNav"
              >Requests</router-link
            >
          </li>
          <li>
            <router-link
              class="link"
              :to="{ name: 'support' }"
              @click="toggleMobileNav"
              >Support</router-link
            >
          </li>
          <li>
            <ion-button
              id="mobile-logout-trigger"
              expand="full"
              :style="{ '--background': branding.color_primary }"
              >Log Out</ion-button
            >
          </li>
          <ion-popover
            trigger="mobile-logout-trigger"
            trigger-action="click"
            side="top"
            alignment="center"
            :dismiss-on-select="true"
          >
            <ion-content
              class="ion-padding"
              :style="{ '--background': branding.color_primary }"
            >
              <ion-row>
                <ion-col class="ion-text-center">
                  <ion-label color="light">Log Out?</ion-label>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="ion-text-center">
                  <ion-button
                    id="mobile-logout-yes-btn"
                    @click="confirmLogout"
                    :style="{ '--background': branding.color_secondary }"
                    >Yes</ion-button
                  >
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="ion-text-center">
                  <ion-button
                    id="mobile-logout-no-btn"
                    :style="{ '--background': branding.color_secondary }"
                    >No</ion-button
                  >
                </ion-col>
              </ion-row>
            </ion-content>
          </ion-popover>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import {
  IonButton,
  IonPopover,
  IonContent,
  IonRow,
  IonCol,
  IonLabel,
  IonIcon,
} from '@ionic/vue';
import { menu, closeOutline } from 'ionicons/icons';
import { useCustomerStore } from '../store/customer';
import { useBrandingStore } from '../store/branding';
import { useRouter } from 'vue-router';
import { Preferences } from '@capacitor/preferences';

const router = useRouter();
const customerStore = useCustomerStore();
const brandingStore = useBrandingStore();
const bannerSrc = ref('');
const scrolledNav = ref(null);
const mobile = ref(null);
const mobileNav = ref(null);
const windowWidth = ref(null);
const branding = brandingStore.brandCSS;

bannerSrc.value = brandingStore.brand.logo_header;

window.addEventListener('resize', checkScreen);
checkScreen();

function toggleMobileNav() {
  mobileNav.value = !mobileNav.value;
}

function updateScroll() {
  const scrollPosition = window.scrollY;
  if (scrollPosition > 50) {
    scrolledNav.value = true;
    return;
  }

  scrolledNav.value = false;
}

function checkScreen() {
  windowWidth.value = window.innerWidth;
  if (windowWidth.value <= 750) {
    mobile.value = true;
    return;
  }

  mobile.value = false;
  mobileNav.value = false;
  return;
}

async function confirmLogout() {
  await Preferences.clear();
  customerStore.$reset();
  router.push({ name: 'login' });
}

onMounted(() => {
  window.addEventListener('scroll', updateScroll);
});
</script>

<style lang="scss" scoped>
header {
  background-color: rgba(255, 255, 255, 1);
  z-index: 99;
  width: 100%;
  position: fixed;
  transition: 0.5s ease all;
  color: #fff;

  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 10px;
    transition: 0.5s ease all;
    width: 90%;
    margin: 12px auto;
    @media (min-width: 1140px) {
      max-width: 1140px;
    }

    ul,
    .link {
      font-weight: bold;
      color: #252539;
      list-style: none;
      text-decoration: none;
    }

    li {
      padding: 15px;
      margin-left: 16px;
    }

    .link {
      font-size: 14px;
      transition: 0.5s ease all;
      padding-bottom: 4px;

      &:hover {
        color: #ef7e31;
      }
    }

    .branding {
      display: flex;
      align-items: center;

      img {
        width: 150px;
        transition: 0.5s ease all;
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: space-between;
    }

    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 24px;
      height: 100%;
      color: #252539;

      ion-icon {
        cursor: pointer;
        transition: 0.8s ease all;
      }
    }

    .icon-active {
      transform: rotate(180deg);
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: #fff;
      top: 0;
      left: 0;
      padding: 10px;
      margin: 0 auto;

      li {
        margin-left: 0;
        .link {
          color: #252539;

          &:hover {
            color: #ef7e31;
          }
        }
      }

      ion-icon {
        margin-left: auto;
      }
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: 1s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
      transform: translateX(-400px);
    }

    .mobile-nav-enter-to {
      transform: translateX(0);
    }
  }
}

.scrolled-nav {
  background-color: #000;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  nav {
    padding: 8px 0;

    .branding {
      img {
        width: 40px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    }
  }
}

ion-icon {
  font-size: 40px;
}
</style>
