<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="VEHICLE DETAILS."
          description="Please review and confirm your vehicle details below."
        />
      </div>
      <SubmitRequestBreadcrumbs />
      <ion-grid :fixed="true" class="ion-padding">
        <ion-row>
          <ion-col>
            <ion-card class="card-container">
              <ion-card-header>
                <ion-card-title>
                  Upload photos of the damage to the vehicle
                  <ion-icon
                    id="hover-trigger"
                    :icon="informationCircleOutline"
                  ></ion-icon>
                  <ion-popover
                    trigger="hover-trigger"
                    trigger-action="hover"
                    :dismiss-on-select="true"
                  >
                    <ion-content class="ion-padding">
                      <div class="ion-text-end">
                        <ion-icon size="large" :icon="closeOutline"></ion-icon>
                      </div>
                      <p>
                        For the best results, we recommend referring to our
                        supporting documentation before proceeding with photo
                        submission.
                      </p>
                      <ion-button
                        id="view-photo-instructions-btn"
                        @click="viewPhotoInstructions(policyId)"
                        :style="{ '--background': branding.color_secondary }"
                        >View photo guidance</ion-button
                      >
                    </ion-content>
                  </ion-popover>
                </ion-card-title>
              </ion-card-header>
              <ion-card-content style="color: #252539">
                Please use the 'Upload Photos' button below to submit at least
                two clear photos of the damage and no more than five photos.
              </ion-card-content>
              <ion-card-content>
                <ion-button
                  id="upload-photos-btn"
                  @click="takePhoto()"
                  :disabled="submittedPhotosCount >= 5"
                  :class="{
                    'button-full-width': isMobile,
                  }"
                  :style="{ '--background': branding.color_primary }"
                  >Upload Photos</ion-button
                >
                <div v-show="!isValid" class="photo-validation ion-padding-top">
                  <p>{{ errorMessagePhotos }}</p>
                </div>
                <ion-grid class="photo-grid">
                  <ion-row>
                    <ion-col
                      v-for="photo in customerStore.submittedPhotos"
                      :key="photo.filename"
                      size-md="5"
                      size-xs="12"
                    >
                      <ion-card color="light" class="photo-card">
                        <ion-card-header>{{ photo.fileName }}</ion-card-header>
                        <ion-button
                          fill="clear"
                          @click="removePhoto(photo.fileName)"
                        >
                          <ion-icon
                            slot="icon-only"
                            :icon="closeOutline"
                            color="secondary"
                          ></ion-icon>
                        </ion-button>
                      </ion-card>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
              <ion-card-content> </ion-card-content>
              <ion-card-content class="ion-text-end">
                <ion-button
                  id="next-btn"
                  @click="nextPage(policyId)"
                  :class="{
                    'button-full-width': isMobile,
                    'button-partial-width': !isMobile,
                  }"
                  :style="{ '--background': branding.color_primary }"
                  >Next</ion-button
                >
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonIcon,
  IonPopover,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from '@ionic/vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { closeOutline } from 'ionicons/icons';
import { useCustomerStore } from '../../store/customer';
import { useBrandingStore } from '../../store/branding';
import BannerComponent from '@/components/BannerComponent.vue';
import SubmitRequestBreadcrumbs from '@/components/SubmitRequestBreadcrumbs.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { informationCircleOutline } from 'ionicons/icons';
import { usePhotoGallery } from '@/composables/usePhotoGallery';
import { useRoute, useRouter } from 'vue-router';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const screenWidth = ref(window.innerWidth);
const isValid = ref(true);
const errorMessagePhotos = ref('');
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const { takePhoto } = usePhotoGallery();
const route = useRoute();
const router = useRouter();
const customerStore = useCustomerStore();
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
const policyId = computed(() => {
  return route.params.policyId;
});
const submittedPhotosCount = computed(
  () => customerStore.submittedPhotos.length
);

desktopBannerSrc.value = brandingStore.brand.desktop_banner_6;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_6;


onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

function viewPhotoInstructions(policyId) {
  router.push({ name: 'photo-instructions', params: { policyId } });
}

function removePhoto(fileName) {
  const photoIndex = customerStore.submittedPhotos.findIndex(
    (obj) => obj.fileName === fileName
  );

  if (photoIndex !== -1) {
    customerStore.submittedPhotos.splice(photoIndex, 1);
  }
}

function nextPage(policyId) {
  if (submittedPhotosCount.value < 2) {
    isValid.value = false;
    errorMessagePhotos.value = 'Please upload more than two photos';
    return;
  }

  isValid.value = true;
  router.push({ name: 'request-details', params: { policyId } });
}
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}

.card-container {
  box-shadow: none;
}

.photo-grid {
  padding: 0;
  margin-top: 40px;
}

.photo-card {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ion-card-header {
    font-weight: bold;
    color: #252539;
  }
}

@media (max-width: 768px) {
  .button-full-width {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .button-partial-width {
    width: 14%;
  }
}

.photo-validation {
  color: red;
}
</style>
