<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="GET IN TOUCH."
          description="Please reach out to us anytime in a way that suits you and a member of the team will respond within two business days."
        />
      </div>
      <ion-grid :fixed="true" class="ion-padding">
        <ion-row>
          <ion-col size-md="4" size-sm="6" size-xs="12">
            <ion-card color="light" class="ion-text-center">
              <img src="../../../public/img/icons/gardxgroup/Call_Us.svg" />
              <ion-card-header>
                <ion-card-title>Call Us</ion-card-title>
                <p>
                  Our friendly teams are ready to help you. Call between 09:00 -
                  17:30 Monday to Friday.
                </p>
              </ion-card-header>
              <a href="tel:02039813580">020 3981 3580</a>
            </ion-card>
          </ion-col>
          <ion-col size-md="4" size-sm="6" size-xs="12">
            <ion-card color="light" class="ion-text-center">
              <img src="../../../public/img/icons/gardxgroup/Email_Us.svg" />
              <ion-card-header>
                <ion-card-title>Email Us</ion-card-title>
                <p>
                  Drop us an email and a memeber of the team will get back to
                  you within two business days.
                </p>
              </ion-card-header>
              <a href="mailto:assureservices@gardxgroup.com"
                >assureservices@gardxgroup.com</a
              >
            </ion-card>
          </ion-col>
          <ion-col size-md="4" size-sm="6" size-xs="12">
            <ion-card color="light" class="ion-text-center">
              <img
                src="../../../public/img/icons/gardxgroup/Request_A_Call_Back.svg"
              />
              <ion-card-header>
                <ion-card-title>Request A Call Back</ion-card-title>
                <p>
                  If you don't have time right now, click here to leave us a
                  message and a member of the team will give you a call during
                  business hours.
                </p>
              </ion-card-header>
              <ion-button
                id="callback-modal"
                :style="{ '--background': branding.color_primary }"
                >Leave a message</ion-button
              >
              <CallbackModal ref="modal" trigger="callback-modal" />
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonButton,
} from '@ionic/vue';
import BannerComponent from '@/components/BannerComponent.vue';
import CallbackModal from '@/components/CallbackModal.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useBrandingStore } from '../../store/branding';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;

desktopBannerSrc.value = brandingStore.brand.desktop_banner_3;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_3;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}

@media (max-width: 768px) {
  ion-card {
    height: 420px;
  }
}

@media (min-width: 769px) {
  ion-card {
    height: 350px;
  }
}

img {
  max-width: 30%;
  padding-top: 20px;
}

a {
  text-decoration: none;
  color: #252539;
  font-weight: bold;
}

p {
  color: #252539;
}
</style>
