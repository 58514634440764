<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="MY REQUESTS."
          :description="isMobile ? mobileDesc : desktopDesc"
        />
      </div>
      <ion-segment v-model="selectedSegment" @ionChange="segmentChanged">
        <ion-segment-button value="inProgress">
          <ion-label>In Progress</ion-label>
        </ion-segment-button>
        <ion-segment-button value="historic">
          <ion-label>Historic</ion-label>
        </ion-segment-button>
      </ion-segment>
      <ion-grid
        v-if="selectedSegment === 'inProgress'"
        :fixed="true"
        class="ion-padding"
      >
        <h2>Requests In Progress</h2>
        <div v-if="currentClaims.length > 0">
          <ion-row v-for="currentClaim in currentClaims" :key="currentClaim.id">
            <ion-col class="ion-padding-top ion-padding-bottom">
              <ion-accordion-group>
                <ion-accordion>
                  <ion-item slot="header" color="light">
                    <ion-label class="ion-text-wrap">{{
                      currentClaim.product
                    }}</ion-label>
                    <ion-label
                      slot="end"
                      :style="{ color: branding.color_primary }"
                      >{{ currentClaim.claim_status }}</ion-label
                    >
                  </ion-item>
                  <div class="ion-padding" slot="content">
                    <ion-grid>
                      <ion-row>
                        <ion-col size-md="3" size-xs="6" class="bordered-col">
                          <ion-list lines="none">
                            <ion-item>
                              <ion-label class="ion-text-wrap">
                                <h4 :style="{ color: branding.color_primary }">
                                  Product Number
                                </h4>
                                <p>{{ currentClaim.policy_no }}</p>
                              </ion-label>
                            </ion-item>
                            <ion-item>
                              <ion-label class="ion-text-wrap">
                                <h4 :style="{ color: branding.color_primary }">
                                  Request Number
                                </h4>
                                <p>{{ currentClaim.claim_no }}</p>
                              </ion-label>
                            </ion-item>
                            <ion-item>
                              <ion-label>
                                <h4 :style="{ color: branding.color_primary }">
                                  Product Term
                                </h4>
                                <p>{{ currentClaim.policy_term }}</p>
                              </ion-label>
                            </ion-item>
                          </ion-list>
                        </ion-col>
                        <ion-col size-md="3" size-xs="6" class="bordered-col">
                          <ion-list lines="none">
                            <ion-item>
                              <ion-label>
                                <h4 :style="{ color: branding.color_primary }">
                                  Start Date
                                </h4>
                                <p>{{ currentClaim.policy_inception_date }}</p>
                              </ion-label>
                            </ion-item>
                            <ion-item>
                              <ion-label>
                                <h4 :style="{ color: branding.color_primary }">
                                  Expiry Date
                                </h4>
                                <p>{{ currentClaim.policy_expiry_date }}</p>
                              </ion-label>
                            </ion-item>
                            <ion-item>
                              <ion-label>
                                <h4 :style="{ color: branding.color_primary }">
                                  Limits
                                </h4>
                                <p>
                                  {{ currentClaim.claims_number_this_year }}/{{
                                    currentClaim.claims_annual_limit
                                  }}
                                </p>
                              </ion-label>
                            </ion-item>
                          </ion-list>
                        </ion-col>
                        <ion-col size-md="3" size="6" class="bordered-col">
                          <ion-list lines="none">
                            <ion-item>
                              <ion-label>
                                <h4 :style="{ color: branding.color_primary }">
                                  Request Status
                                </h4>
                                <p>{{ currentClaim.claim_status }}</p>
                              </ion-label>
                            </ion-item>
                            <ion-item>
                              <ion-label>
                                <h4 :style="{ color: branding.color_primary }">
                                  Request Raised
                                </h4>
                                <p>{{ currentClaim.date_of_loss }}</p>
                              </ion-label>
                            </ion-item>
                            <ion-item>
                              <ion-label>
                                <h4 :style="{ color: branding.color_primary }">
                                  Product Status
                                </h4>
                                <p>{{ currentClaim.policy_status }}</p>
                              </ion-label>
                            </ion-item>
                          </ion-list>
                        </ion-col>
                        <ion-col size-md="3" size-xs="12">
                          <ion-list lines="none">
                            <ion-item>
                              <ion-label class="ion-text-wrap">
                                <h4 :style="{ color: branding.color_primary }">
                                  Supporting Documents
                                </h4>
                                <p>View your submitted documents here.</p>
                                <ion-button
                                  id="view-document-btn"
                                  expand="full"
                                  @click="viewDocument(currentClaim.link)"
                                  :style="{
                                    '--background': branding.color_primary,
                                  }"
                                  >View Documents</ion-button
                                >
                                <!-- <ion-button expand="full"
                                  >View Images</ion-button
                                > -->
                              </ion-label>
                            </ion-item>
                          </ion-list>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </div>
                  <div class="ion-padding" slot="content"></div>
                </ion-accordion>
              </ion-accordion-group>
            </ion-col>
          </ion-row>
        </div>
        <p v-else>No requests in progress</p>
      </ion-grid>
      <ion-grid
        v-else-if="selectedSegment === 'historic'"
        :fixed="true"
        class="ion-padding"
      >
        <h2>Historic Requests</h2>
        <p>Keep track of past requests.</p>
        <div v-if="historicClaims.length > 0">
          <ion-row
            v-for="historicClaim in historicClaims"
            :key="historicClaim.id"
          >
            <ion-col>
              <ion-card color="light">
                <ion-card-content>
                  <ion-grid>
                    <ion-row>
                      <ion-col size-md="4" size-xs="12" class="bordered-col">
                        <ion-list lines="none">
                          <ion-item>
                            <ion-label>
                              <h3>{{ historicClaim.product }}</h3>
                            </ion-label>
                          </ion-item>
                        </ion-list>
                      </ion-col>
                      <ion-col size-md="2" size-xs="6" class="bordered-col">
                        <ion-list lines="none">
                          <ion-item>
                            <ion-label class="ion-text-wrap">
                              <h4 :style="{ color: branding.color_primary }">
                                Request Status
                              </h4>
                              <p
                                :class="{
                                  'status-complete':
                                    historicClaim.claim_status ===
                                    'Case Completed',
                                  'status-declined':
                                    historicClaim.claim_status === 'Declined',
                                }"
                              >
                                {{ historicClaim.claim_status }}
                              </p>
                            </ion-label>
                          </ion-item>
                        </ion-list>
                      </ion-col>
                      <ion-col size-md="2" size-xs="6" class="bordered-col">
                        <ion-list lines="none">
                          <ion-item>
                            <ion-label class="ion-text-wrap">
                              <h4 :style="{ color: branding.color_primary }">
                                Request Raised
                              </h4>
                              <p>{{ historicClaim.date_of_loss }}</p>
                            </ion-label>
                          </ion-item>
                        </ion-list>
                      </ion-col>
                      <ion-col size-md="4" size-xs="6">
                        <ion-list lines="none">
                          <ion-item>
                            <ion-label class="ion-text-wrap">
                              <h4 :style="{ color: branding.color_primary }">
                                Request Number
                              </h4>
                              <p>{{ historicClaim.claim_no }}</p>
                            </ion-label>
                          </ion-item>
                        </ion-list>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </div>
        <p v-else>No historic claims</p>
      </ion-grid>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonList,
  IonButton,
  IonCard,
  IonCardContent,
} from '@ionic/vue';
import BannerComponent from '@/components/BannerComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useCustomerStore } from '../store/customer';
import { useBrandingStore } from '../store/branding';

const customerStore = useCustomerStore();
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
const historicClaims = computed(() => {
  return customerStore.claims.filter(
    (claim) =>
      claim.claim_status === 'Case Completed' ||
      claim.claim_status === 'Decline'
  );
});
const currentClaims = computed(() => {
  return customerStore.claims.filter(
    (claim) =>
      claim.claim_status !== 'Case Completed' &&
      claim.claim_status !== 'Decline'
  );
});
const selectedSegment = ref('inProgress');
const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const mobileDesc = ref(
  'Keep track of your submitted requests progress, past and present. Stay in the know and have complete visibility of all your reports.'
);
const desktopDesc = ref(
  'Keep track of your submitted requests progress, past and present.'
);
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};

desktopBannerSrc.value = brandingStore.brand.desktop_banner_5;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_5;

onMounted(() => {
  window.addEventListener('resize', handleResize);
  customerStore.fetchClaims();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

function segmentChanged(segment) {
  selectedSegment.value = segment.detail.value;
}

function viewDocument(link) {
  window.open(link, '_blank');
}
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

.banner-container {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

h3 {
  font-weight: bold;
}

h4 {
  color: #ef7e31;
  font-weight: bold;
}

p {
  color: #252539;
}

.status-complete {
  color: #15a50a;
}

.status-rejected {
  color: #c92323;
}

ion-segment {
  --background: #ffffff;
}

ion-segment-button {
  --indicator-color: v-bind(branding.color_primary);
  text-transform: none;
}

/* Material Design styles */
ion-segment-button.md {
  --color: v-bind(branding.color_secondary);
  --color-checked: v-bind(branding.color_primary);
}

/* iOS styles */
ion-segment-button.ios {
  --color: v-bind(branding.color_secondary);
  --color-checked: #fff;
  --border-radius: 0;
}

ion-accordion-group {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

div[slot='content'] {
  background: #ffffff;
}

ion-item {
  --background: #ffffff;
}

ion-item[slot='header'] {
  font-weight: bold;
}

ion-list {
  background: #ffffff;
}

ion-card {
  margin: 0 auto;
}

@media (min-width: 769px) {
  .bordered-col {
    border-right: 1px solid #f2f2f2;
  }
}
</style>
