import { ref, onMounted, watch } from 'vue';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { useCustomerStore } from '../store/customer';

export function usePhotoGallery() {
  const PHOTO_STORAGE = 'photos';
  const photos = ref([]);
  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });

    const fileName = new Date().getTime() + '.jpeg';
    const savedFileImage = await savePicture(photo, fileName);

    photos.value = [savedFileImage, ...photos.value];

    const cachePhotos = () => {
      Preferences.set({
        key: PHOTO_STORAGE,
        value: JSON.stringify(photos.value),
      });
    };

    watch(photos, cachePhotos);

    const loadSaved = async () => {
      const photoList = await Preferences.get({ key: PHOTO_STORAGE });
      const photosInPreferences = photoList.value
        ? JSON.parse(photoList.value)
        : [];

      for (const photo of photosInPreferences) {
        const file = await Filesystem.readFile({
          path: photo.filepath,
          directory: Directory.Data,
        });
        photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
      }

      photos.value = photosInPreferences;
    };

    onMounted(loadSaved);
  };

  return {
    photos,
    takePhoto,
  };
}

/**
 * @typedef {Object} UserPhoto
 * @property {string} filepath - A string representing the file path where the user's photo is stored.
 * @property {string} [webviewPath] - An optional string representing the web view path for the user's photo.
 */

const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

const savePicture = async (photo, fileName) => {
  const customerStore = useCustomerStore();
  let base64Data;
  // Fetch the photo, read as a blob, then convert to base64 format
  const response = await fetch(photo.webPath);
  const blob = await response.blob();
  base64Data = await convertBlobToBase64(blob);
  const base64WithoutPrefix = base64Data.substring(base64Data.indexOf(',') + 1);

  const savedFile = await Filesystem.writeFile({
    path: fileName,
    data: base64Data,
    directory: Directory.Data,
  });

  customerStore.submittedPhotos.push({ fileName, data: base64WithoutPrefix });

  // Use webPath to display the new image instead of base64 since it's
  // already loaded into memory
  return {
    filepath: savedFile.path,
    webviewPath: photo.webPath,
  };
};
