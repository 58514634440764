<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="VIDEO SUPPORT."
          description="Find answers to your questions with our easy-to-follow videos."
        />
      </div>
      <ion-grid :fixed="true" class="ion-padding">
        <div>
          <h2>How To Videos</h2>
        </div>
        <ion-row>
          <ion-col size-md="4" size-sm="6" size-xs="12">
            <ion-card color="light">
              <video controls>
                <source
                  src="../../../public/assets/gardxgroup/videos/Alloy_Photo_Guide.mp4"
                  type="video/mp4"
                />
                Your browser does not support the vidoe tag.
              </video>
              <ion-card-content class="ion-text-center">
                Photo Guide - Damaged Alloy Wheels
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col size-md="4" size-sm="6" size-xs="12">
            <ion-card color="light">
              <video controls>
                <source
                  src="../../../public/assets/gardxgroup/videos/CARS_Photo_Guide.mp4"
                  type="video/mp4"
                />
                Your browser does not support the vidoe tag.
              </video>
              <ion-card-content class="ion-text-center">
                Photo Guide - C.A.R.S Plus Protection
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col size-md="4" size-sm="6" size-xs="12">
            <ion-card color="light">
              <video controls>
                <source
                  src="../../../public/assets/gardxgroup/videos/Wheels_Photo_Guide.mp4"
                  type="video/mp4"
                />
                Your browser does not support the vidoe tag.
              </video>
              <ion-card-content class="ion-text-center">
                Photo Guide - Damaged Tyres
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div
        id="contact-container"
        :style="{ 'background-color': branding.contact_container_color }"
      >
        <ion-grid :fixed="true" class="ion-padding">
          <ContactCard
            :getInTouchButton="true"
            :seeFAQ="true"
            title="Can't find what you're looking for?"
            description="Please check out our FAQs or get in touch with the team."
          />
        </ion-grid>
      </div>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
} from '@ionic/vue';
import BannerComponent from '@/components/BannerComponent.vue';
import ContactCard from '@/components/ContactCard.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useBrandingStore } from '../../store/branding';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;

desktopBannerSrc.value = brandingStore.brand.desktop_banner_10;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_10;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}

video {
  width: 100%;
  height: 100%;
}

ion-card-content {
  color: #252539;
}

ion-card {
  margin: 0 auto;
}
</style>
