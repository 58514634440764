<template>
  <ion-card>
    <img :src="logoSrc" />
    <ion-card-header>
      <ion-card-title color="light">Welcome</ion-card-title>
      <p>Log in to manage your car protection products.</p>
    </ion-card-header>
    <ion-card-content>
      <div class="input-container">
        <ion-label color="light">Vehicle Registration</ion-label>
        <ion-item
          color="light"
          lines="none"
          :class="{ 'ion-invalid': !isValid }"
        >
          <ion-input v-model="regNo"></ion-input>
          <ion-note slot="error">{{ errorMessageRegNo }}</ion-note>
        </ion-item>
        <ion-label color="light">Surname</ion-label>
        <ion-item
          color="light"
          lines="none"
          :class="{ 'ion-invalid': !isValid }"
        >
          <ion-input v-model="surname"></ion-input>
          <ion-note slot="error">{{ errorMessageSurname }}</ion-note>
        </ion-item>
      </div>
      <ion-button
        id="login-btn"
        @click="logIn()"
        :class="{
          'button-full-width': isMobile,
          'button-partial-width': !isMobile,
        }"
        :style="{ '--background': branding.color_primary }"
      >
        <span v-if="!isLoading">Log In</span>
        <ion-spinner v-if="isLoading"></ion-spinner>
      </ion-button>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import {
  IonCardHeader,
  IonCardTitle,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonNote,
  IonButton,
  IonSpinner,
} from '@ionic/vue';
import axios from 'axios';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useBrandingStore } from '../store/branding';
import * as Sentry from '@sentry/vue';

const router = useRouter();
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
const logoSrc = ref('');
const surname = ref('');
const regNo = ref('');
const isValid = ref(true);
const errorMessageSurname = ref('');
const errorMessageRegNo = ref('');
const isLoading = ref(false);
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};

logoSrc.value = brandingStore.brand.logo_footer;

async function logIn() {
  isLoading.value = true;

  if (!surname.value || !regNo.value) {
    if (!surname.value) {
      errorMessageSurname.value = 'Please enter your surname';
    }

    if (!regNo.value) {
      errorMessageRegNo.value = 'Please enter your vehicle registration';
    }

    isValid.value = false;
    isLoading.value = false;
    return;
  }

  try {
    const url = `${process.env.VUE_APP_API_BASE_URL}/signIn`;
    const data = {
      surname: surname.value,
      reg_no: regNo.value,
    };
    const response = await axios.post(url, data);

    if (response.data.success) {
      isValid.value = true;
      router.push({ name: 'verify' });
    }
  } catch (error) {
    if (error.response) {
      errorMessageSurname.value =
        'Could not find any product related to the customer.';
      isValid.value = false;
      Sentry.captureException(error.response.data.error);
      Sentry.captureMessage(error.response.data.error.message);
    }

    Sentry.captureException(error);
    Sentry.captureMessage(error.message);
  } finally {
    isLoading.value = false;
  }
}

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
ion-card {
  width: 100%;
  max-width: 500px;
  box-shadow: none;
  text-align: center;

  img {
    max-width: 60%;
  }

  ion-card-title {
    padding: 10px;
    font-size: xx-large;
  }

  ion-card-subtitle {
    padding: 10px;
  }

  .input-container {
    text-align: start;
  }

  ion-label {
    margin-left: 8px;
  }

  ion-item {
    padding: 10px;
  }

  p {
    color: #ffffff;
  }
}

@media (max-width: 768px) {
  .button-full-width {
    width: 94%;
  }
}

@media (min-width: 769px) {
  .button-partial-width {
    width: 42%;
  }
}
</style>
