<template>
  <ion-breadcrumbs
    :max-items="isMobile ? 2 : 6"
    class="ion-justify-content-center"
  >
    <ion-breadcrumb :active="isActive('personal')">
      Personal Details
      <ion-icon slot="separator" :icon="arrowForwardCircle"></ion-icon>
    </ion-breadcrumb>
    <ion-breadcrumb :active="isActive('vehicle') || isActive('instructions')">
      Vehicle Details
      <ion-icon slot="separator" :icon="arrowForwardCircle"></ion-icon>
    </ion-breadcrumb>
    <ion-breadcrumb :active="isActive('submit')">
      Submit Photos
      <ion-icon slot="separator" :icon="arrowForwardCircle"></ion-icon>
    </ion-breadcrumb>
    <ion-breadcrumb :active="isActive('request')">
      Request Details
      <ion-icon slot="separator" :icon="arrowForwardCircle"></ion-icon>
    </ion-breadcrumb>
    <ion-breadcrumb :active="isActive('questions')">
      Additional Questions
      <ion-icon slot="separator" :icon="arrowForwardCircle"></ion-icon>
    </ion-breadcrumb>
    <ion-breadcrumb>
      Submit Request
      <ion-icon slot="separator" :icon="arrowForwardCircle"></ion-icon>
    </ion-breadcrumb>
  </ion-breadcrumbs>
</template>

<script setup>
import { IonBreadcrumbs, IonBreadcrumb, IonIcon } from '@ionic/vue';
import { arrowForwardCircle } from 'ionicons/icons';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute } from 'vue-router';

const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const route = useRoute();
const isActive = (activeRoute) => {
  return route.name.includes(activeRoute);
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>
