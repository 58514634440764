export default {
  'Front Bumper [BODY]': 'car_front',
  'N/S/F Wing [BODY]': 'car_front_side',
  'N/S/F Door [BODY]': 'car_front_side',
  'N/S/R Door [BODY]': 'car_rear_side',
  'N/S/R Quarter Panel [BODY]': 'car_rear_side',
  'Rear Bumper [BODY]': 'car_rear',
  'Rear Tailgate [BODY]': 'car_rear',
  'O/S/R Quarter Panel [BODY]': 'car_rear',
  'O/S/R Door [BODY]': 'car_rear_side',
  'O/S/F Door [BODY]': 'car_front_side',
  'O/S/F Wing [BODY]': 'car_front_side',
  'N/S Spoiler / Skirt [BODY]': 'car_rear_side',
  'Rear Spoiler / Skirt [BODY]': 'car_rear',
  'O/S Spoiler / Skirt [BODY]': 'car_rear_side',
  'Front Grille [BODY]': 'car_front',
  'VPS - multiple parts [BODY]': 'car_front',
  'Citroen Airbumps [BODY]': 'car_front',
  'Roof Panel [BODY]': 'car_front_roof',
  'Bonnet [BODY]': 'car_front',
  'N/S/F Door Mirror Casing [BODY]': 'car_front_side',
  'O/S/F Door Mirror Casing [BODY]': 'car_front_side',
  'O/S/F Tyre [TYRES]': 'car_tyre_wheel',
  'O/S/R Tyre [TYRES]': 'car_tyre_wheel',
  'N/S/F Tyre [TYRES]': 'car_tyre_wheel',
  'N/S/R Tyre [TYRES]': 'car_tyre_wheel',
  'Front Tyre [TYRES]': 'car_tyre_wheel',
  'Rear Tyre [TYRES]': 'car_tyre_wheel',
  'N/S/F Wheel [WHEELS]': 'car_tyre_wheel',
  'N/S/R Wheel [WHEELS]': 'car_tyre_wheel',
  'O/S/F Wheel [WHEELS]': 'car_tyre_wheel',
  'O/S/R Wheel [WHEELS]': 'car_tyre_wheel',
};
