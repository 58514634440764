<template>
  <ion-card color="light">
    <ion-card-content>
      <ion-grid :fixed="true">
        <ion-row>
          <ion-col size-md="4" size-xs="12" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">
                    First Name(s)
                  </h4>
                  <p>{{ customer.first_name }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">Surname</h4>
                  <p>{{ customer.last_name }}</p>
                </ion-label>
              </ion-item>
              <ion-item v-show="customer.email">
                <ion-label class="ion-text-wrap">
                  <h4 :style="{ color: branding.color_primary }">Email</h4>
                  <p>{{ customer.email }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">Telephone</h4>
                  <p>{{ customer.cell_phone }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="4" size-xs="12" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h4 :style="{ color: branding.color_primary }">Address</h4>
                  <p>{{ customer.address1 }}, {{ customer.address2 }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">Town</h4>
                  <p>{{ customer.city }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">Postcode</h4>
                  <p>{{ customer.postal_code }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="4" size-xs="12">
            <ion-list lines="none">
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">
                    Vehicle Make
                  </h4>
                  <p>{{ vehicle.make }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">
                    Vehicle Model
                  </h4>
                  <p>{{ vehicle.model }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">
                    Registration
                  </h4>
                  <p>{{ vehicle.vrm }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">VIN</h4>
                  <p>{{ vehicle.vin }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
} from '@ionic/vue';
import { defineProps } from 'vue';
import { useBrandingStore } from '../store/branding';

defineProps({
  customer: {
    type: Object,
    required: true,
  },
  vehicle: {
    type: Object,
    required: true,
  },
});

const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
</script>

<style lang="scss" scoped>
h4 {
  color: #ef7e31;
  font-weight: bold;
}

p {
  color: #252539;
}

ion-card {
  margin: 0 auto;
}

ion-item {
  --background: #ffffff;
}

ion-list {
  background: #ffffff;
}

@media (max-width: 768px) {
  .bordered-col {
    border-bottom: 1px solid #f2f2f2; /* Border at the bottom for small screens */
  }
}

@media (min-width: 769px) {
  .bordered-col {
    border-right: 1px solid #f2f2f2; /* Border on the right for large screens */
  }
}
</style>
