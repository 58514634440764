<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="FREQUENTLY ASKED QUESTIONS."
          description="Find quick answers to your questions in our FAQs below."
        />
      </div>
      <ion-grid :fixed="true" class="ion-padding">
        <ion-row class="ion-padding-top">
          <ion-col size-md="4" size-xs="12">
            <ion-list lines="none">
              <ion-item>
                <ion-label>
                  <h2>Requests Support</h2>
                  <ion-text id="report-change-modal">
                    <p class="ion-padding-top">How do I report a change?</p>
                  </ion-text>
                  <FAQModal
                    ref="modal"
                    trigger="report-change-modal"
                    faqTitle="How do I report a change?"
                    faqAnswer="To avoid complications in the settlement your claim, we
                        would appreciate your timely notification of any changes
                        to your insurance (e.g. changes to the vehicle’s registration).
                        Please contact us on 02039813580 and a member of our
                        team will guide you through the process."
                  />
                  <ion-text id="make-request-modal">
                    <p class="ion-padding-top">How do I make a request?</p>
                  </ion-text>
                  <FAQModal
                    ref="modal"
                    trigger="make-request-modal"
                    faqTitle="How do I make a request?"
                    faqAnswer="When you become aware of any event that could lead to a request, please visit at Submit a request. 
                    Select your plan and our step-by-step process will guide you through each stage of your request, with clear instructions and helpful support. 
                    We aim to make the claims process as efficient as possible. We therefore ask for your cooperation in complying with the claims process, as well as the timely delivery of any required documents as per your policy terms and conditions."
                  />
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="4" size-xs="12">
            <ion-list lines="none">
              <ion-item>
                <ion-label>
                  <h2>Manage Renewal</h2>
                  <ion-text id="cancel-insurance-modal">
                    <p class="ion-padding-top">
                      How can I cancel my insurance?
                    </p>
                  </ion-text>
                  <FAQModal
                    ref="modal"
                    trigger="cancel-insurance-modal"
                    faqTitle="How can I cancel my insurance?"
                    faqAnswer="We are sorry to see you go. 
                  You can cancel your insurance policy by sending us a completed and signed cancellation form by mail or by email. 
                  Please contact us on 020 3981 3580 and a member of our team will guide you through the process."
                  />
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="4" size-xs="12">
            <ion-list lines="none">
              <ion-item>
                <ion-label>
                  <h2>Check details and documents</h2>
                  <ion-text id="about-me-modal">
                    <p class="ion-padding-top">
                      What information do you have about me?
                    </p>
                  </ion-text>
                  <FAQModal
                    ref="modal"
                    trigger="about-me-modal"
                    faqTitle="What Information do you have about me?"
                    faqAnswer="We will only use the information you provide us to administer your policy and handle any claims. 
                  The information we process includes: 
                  •	address and identification data (including date of birth, identifying information, nationality, tax residence) 
                  •	data on agreed services and other information you provide to us or data that we find out during the provision of services, including, data that we create in connection with our obligation to process the data obtained 
                  "
                  />
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div
        id="contact-container"
        :style="{ 'background-color': branding.contact_container_color }"
      >
        <ion-grid :fixed="true" class="ion-padding">
          <ContactCard
            :getInTouchButton="true"
            title="Can't find what you're looking for?"
            description="Please get in touch with the team."
          />
        </ion-grid>
      </div>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/vue';
import BannerComponent from '@/components/BannerComponent.vue';
import FAQModal from '@/components/FAQModal.vue';
import ContactCard from '@/components/ContactCard.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useBrandingStore } from '../../store/branding';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;

desktopBannerSrc.value = brandingStore.brand.desktop_banner_2;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_2;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}

h2 {
  font-weight: bold;
}

p:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
