<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="ADDITIONAL QUESTIONS."
          description="Please take a moment to answer the additional questions below
            providing as much details as possible."
        />
      </div>
      <SubmitRequestBreadcrumbs />
      <ion-grid :fixed="true" class="ion-padding">
        <ion-row class="ion-padding-top ion-padding-bottom ion-align-items-end">
          <ion-col
            size-md="6"
            size-xs="12"
            v-for="additionalQuestion in additionalQuestions"
            :key="additionalQuestion.id"
          >
            <ion-list>
              <ion-label class="ion-text-wrap">{{
                additionalQuestion.question
              }}</ion-label>
              <ion-item
                lines="none"
                :class="{
                  'ion-invalid':
                    !isValid &&
                    !customerStore.questionsAnswers[additionalQuestion.id],
                }"
              >
                <ion-textarea
                  v-model="
                    customerStore.questionsAnswers[additionalQuestion.id]
                  "
                  :auto-grow="true"
                ></ion-textarea>
                <ion-note slot="error">{{ errorMessageAnswers }}</ion-note>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-end">
          <p style="color: red" v-show="isError">
            There was an error submitting your request. Please contact support.
          </p>
          <ion-col size-md="2" size-xs="12">
            <ion-button
              id="submit-request-btn"
              :disabled="isError"
              expand="full"
              @click="submit(policyId)"
              :style="{ '--background': branding.color_primary }"
            >
              <span v-if="!isLoading">Next</span>
              <ion-spinner v-if="isLoading"></ion-spinner>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonTextarea,
  IonNote,
  IonButton,
  IonSpinner,
} from '@ionic/vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import BannerComponent from '@/components/BannerComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import SubmitRequestBreadcrumbs from '@/components/SubmitRequestBreadcrumbs.vue';
import { useRoute, useRouter } from 'vue-router';
import { useCustomerStore } from '../../store/customer';
import { useBrandingStore } from '../../store/branding';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const screenWidth = ref(window.innerWidth);
const isLoading = ref(false);
const isValid = ref(true);
const errorMessageAnswers = ref('');
const isError = ref(false);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const route = useRoute();
const router = useRouter();
const customerStore = useCustomerStore();
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
const policyId = computed(() => {
  return route.params.policyId;
});
const additionalQuestions = computed(() => {
  return customerStore.additionalQuestions;
});
const answerCount = computed(
  () => Object.keys(customerStore.questionsAnswers).length
);
const questionCount = computed(() => customerStore.additionalQuestions.length);

desktopBannerSrc.value = brandingStore.brand.desktop_banner_6;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_6;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

async function submit(policyId) {
  if (answerCount.value < questionCount.value) {
    isValid.value = false;
    errorMessageAnswers.value = 'Please answer the additional question';
    return;
  }

  if (
    Object.values(customerStore.questionsAnswers).some((value) => value === '')
  ) {
    isValid.value = false;
    errorMessageAnswers.value = 'Please answer the additional question';
    return;
  }

  isLoading.value = true;
  const response = await customerStore.submitClaim(policyId);

  if (response === undefined) {
    isError.value = true;
    isLoading.value = false;
    return;
  }

  if (response.data.success) {
    router.push({ name: 'thank-you' });
  }

  isLoading.value = false;
}
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}

ion-item {
  --background: #ffffff;
  padding-top: 10px;
}
</style>
