<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="REQUEST DETAILS."
          description="Please provide us with as much information as possible about the location of the damage."
        />
      </div>
      <SubmitRequestBreadcrumbs />
      <ion-grid :fixed="true" class="ion-padding">
        <ion-row class="ion-padding-bottom ion-padding-top">
          <ion-col size-md="6" size-xs="12">
            <ion-label>Date of incident</ion-label>
            <ion-item lines="none" :class="{ 'ion-invalid': !isValid }">
              <ion-icon :icon="calendarClearOutline" slot="end"></ion-icon>
              <ion-input id="datetime" v-model="formattedDate"></ion-input>
              <ion-note slot="error">{{ errorMessageDate }}</ion-note>
              <ion-popover trigger="datetime">
                <ion-datetime
                  v-model="customerStore.dateOfLoss"
                  id="datetime"
                  presentation="date"
                  :show-default-buttons="true"
                  :max="maxDate"
                ></ion-datetime>
              </ion-popover>
            </ion-item>
          </ion-col>
          <ion-col size-md="6" size-xs="12">
            <ion-label>Country of incident</ion-label>
            <ion-item lines="none" :class="{ 'ion-invalid': !isValid }">
              <ion-select
                interface="popover"
                v-model="customerStore.countryIncident"
              >
                <ion-select-option value="uk">UK</ion-select-option>
                <ion-select-option value="ireland">Ireland</ion-select-option>
                <ion-select-option value="channelIslands"
                  >Channel Islands</ion-select-option
                >
                <ion-select-option value="isleOfMan"
                  >Isle Of Man</ion-select-option
                >
                <ion-select-option value="europeanUnion"
                  >European Union</ion-select-option
                >
                <ion-select-option value="croatia">Croatia</ion-select-option>
                <ion-select-option value="iceland">Iceland</ion-select-option>
                <ion-select-option value="norway">Norway</ion-select-option>
                <ion-select-option value="switzerland"
                  >Switzerland</ion-select-option
                >
                <ion-select-option value="liechtenstein"
                  >Liechtenstien</ion-select-option
                >
                <ion-select-option value="andorra">Andorra</ion-select-option>
                <ion-select-option value="other">Other</ion-select-option>
              </ion-select>
              <ion-note slot="error">{{ errorMessageCountry }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding-bottom ion-padding-top">
          <ion-col size-md="6" size-xs="12">
            <ion-label>Type of damage</ion-label>
            <ion-item lines="none" :class="{ 'ion-invalid': !isValid }">
              <ion-select
                interface="popover"
                v-model="customerStore.claimLossTypeId"
              >
                <ion-select-option
                  v-for="lossType in lossTypes"
                  :key="lossType.id"
                  :value="lossType.id"
                  >{{ lossType.type }}</ion-select-option
                >
              </ion-select>
              <ion-note slot="error">{{ errorMessageDamageType }}</ion-note>
            </ion-item>
          </ion-col>
          <ion-col size-md="6" size-xs="12">
            <ion-label position="stacked">Cause of damage</ion-label>
            <ion-item lines="none" :class="{ 'ion-invalid': !isValid }">
              <ion-select
                interface="popover"
                v-model="customerStore.claimCauseTypeId"
              >
                <ion-select-option
                  v-for="causeType in causeTypes"
                  :key="causeType.id"
                  :value="causeType.id"
                  >{{ causeType.type }}</ion-select-option
                >
              </ion-select>
              <ion-note slot="error">{{ errorMessageDamageCause }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding-bottom ion-padding-top">
          <ion-col size-md="6" size-xs="12">
            <ion-label>Damage location</ion-label>
            <ion-item lines="none" :class="{ 'ion-invalid': !isValid }">
              <ion-select
                @ionChange="damageLocation"
                interface="popover"
                v-model="customerStore.claimCausalPartId"
              >
                <ion-select-option
                  v-for="causalPart in causalParts"
                  :key="causalPart.id"
                  :value="causalPart.id"
                  >{{ causalPart.part }}</ion-select-option
                >
              </ion-select>
              <ion-note slot="error">{{ errorMessageDamageLocation }}</ion-note>
            </ion-item>
            <ion-list v-if="!isMobile" lines="none" class="ion-padding-top">
              <ion-label>Brief description of accident</ion-label>
              <ion-item lines="none" :class="{ 'ion-invalid': !isValid }">
                <ion-textarea
                  v-model="customerStore.causeNotes"
                  :auto-grow="true"
                ></ion-textarea>
                <ion-note slot="error">{{ errorMessageNotes }}</ion-note>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col
            v-show="product && !product.includes('Paint')"
            size-md="6"
            size-xs="12"
          >
            <ion-label>Select the area of damage on the diagram:</ion-label>
            <div class="image-container">
              <img
                ref="originalImage"
                :src="imageSource"
                alt="Car Diagram"
                @click="addMarker"
              />
              <div
                v-if="showMarker"
                class="marker"
                :style="{ top: markerTop + 'px', left: markerLeft + 'px' }"
              ></div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-between">
          <ion-col size-md="6" size-xs="12">
            <ion-list v-if="isMobile" lines="none">
              <ion-label>Brief description of accident</ion-label>
              <ion-item lines="none" :class="{ 'ion-invalid': !isValid }">
                <ion-textarea
                  v-model="customerStore.causeNotes"
                  :auto-grow="true"
                ></ion-textarea>
                <ion-note slot="error">{{ errorMessageNotes }}</ion-note>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="2" size-xs="12">
            <ion-button
              id="next-btn"
              expand="full"
              @click="nextPage(policyId, customerStore.claimLossTypeId)"
              :style="{ '--background': branding.color_primary }"
              >Next</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonNote,
  IonIcon,
  IonDatetime,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonList,
  IonTextarea,
  IonButton,
} from '@ionic/vue';
import { calendarClearOutline } from 'ionicons/icons';
import { ref, onMounted, onUnmounted, computed, watchEffect } from 'vue';
import BannerComponent from '@/components/BannerComponent.vue';
import SubmitRequestBreadcrumbs from '@/components/SubmitRequestBreadcrumbs.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { useRoute, useRouter } from 'vue-router';
import { useCustomerStore } from '../../store/customer';
import { useBrandingStore } from '../../store/branding';
import valueToImageMapping from '../../composables/valueToImageMapping';
import { formatDate } from '../../composables/formatDate';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const screenWidth = ref(window.innerWidth);
const isValid = ref(true);
const errorMessageDate = ref('');
const errorMessageCountry = ref('');
const errorMessageDamageType = ref('');
const errorMessageDamageCause = ref('');
const errorMessageDamageLocation = ref('');
const errorMessageNotes = ref('');
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const route = useRoute();
const router = useRouter();
const customerStore = useCustomerStore();
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
const policyId = computed(() => {
  return route.params.policyId;
});
const policy = computed(() => {
  return customerStore.policies.find((p) => p.id === policyId.value);
});
const product = computed(() => {
  const policy = customerStore.policies.find(
    (policy) => policy.id === policyId.value
  );
  return policy?.product;
});
const causalParts = Object.entries(policy.value.CausalParts).map(
  ([id, part]) => ({ id, part })
);
const causeTypes = Object.entries(policy.value.CauseTypes).map(
  ([id, type]) => ({ id, type })
);
const lossTypes = Object.entries(policy.value.LossTypes).map(([id, type]) => ({
  id,
  type,
}));
const imageSource = ref(
  require('../../../public/assets/gardxgroup/car_diagrams/car_front.jpg')
);
const showMarker = ref(false);
const markerTop = ref(0);
const markerLeft = ref(0);
const formattedDate = ref('');
const maxDate = computed(() => {
  return new Date().toISOString().split('T')[0]; // Set maximum date to the current date
});
const addMarker = (event) => {
  showMarker.value = true;
  const containerRect = event.target.getBoundingClientRect();
  const offsetX = event.clientX - containerRect.left;
  const offsetY = event.clientY - containerRect.top;
  markerTop.value = offsetY;
  markerLeft.value = offsetX;
};

desktopBannerSrc.value = brandingStore.brand.desktop_banner_6;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_6;

async function nextPage(policyId, claimLossTypeId) {
  if (
    customerStore.dateOfLoss === '' ||
    customerStore.claimCountry === '' ||
    customerStore.claimLossTypeId === '' ||
    customerStore.claimCauseTypeId === '' ||
    customerStore.claimCausalPartId === '' ||
    customerStore.causeNotes === ''
  ) {
    if (!customerStore.dateOfLoss) {
      errorMessageDate.value = 'Please select the date of the incident';
    }
    if (!customerStore.countryIncident) {
      errorMessageCountry.value = 'Please select a country';
    }
    if (!customerStore.claimLossTypeId) {
      errorMessageDamageType.value = 'Please select the type of damage';
    }
    if (!customerStore.claimCauseTypeId) {
      errorMessageDamageCause.value = 'Please select the cause of damage';
    }
    if (!customerStore.claimCausalPartId) {
      errorMessageDamageLocation.value = 'Please select the damage location';
    }
    if (!customerStore.causeNotes) {
      errorMessageNotes.value =
        'Please give a brief description of the accident';
    }

    isValid.value = false;
    return;
  }

  isValid.value = true;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const originalImage = ref(null);

  const onLoad = () => {
    canvas.width = originalImage.value.width;
    canvas.height = originalImage.value.height;

    context.drawImage(originalImage.value, 0, 0);
    context.fillStyle = 'red';
    context.beginPath();
    context.arc(markerLeft.value, markerTop.value, 5, 0, Math.PI * 2);
    context.fill();

    const base64Image = canvas.toDataURL('image/jpeg');
    const base64WithoutPrefix = base64Image.substring(
      base64Image.indexOf(',') + 1
    );
    const fileName = imageSource.value.substring(5);

    customerStore.submittedPhotos.push({ fileName, data: base64WithoutPrefix });
  };

  originalImage.value = new Image();
  originalImage.value.addEventListener('load', onLoad);
  originalImage.value.src = imageSource.value;

  const response = await customerStore.fetchAdditionalQuestions(
    claimLossTypeId
  );

  if (response.data.success) {
    router.push({
      name: 'additional-questions',
      params: { policyId, claimLossTypeId },
    });
  }
}

function damageLocation(event) {
  const selectedOption = event.target.value;
  const selectedCausalPart = causalParts.find((cp) => cp.id === selectedOption);
  const selectedPart = selectedCausalPart?.part;

  if (selectedPart in valueToImageMapping) {
    const imageName = valueToImageMapping[selectedPart];
    imageSource.value = require(`../../../public/assets/gardxgroup/car_diagrams/${imageName}.jpg`);
  }
}

onMounted(async () => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

watchEffect(() => {
  if (customerStore.dateOfLoss) {
    formattedDate.value = formatDate(new Date(customerStore.dateOfLoss));
  } else {
    formattedDate.value = '';
  }
});
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}

ion-item {
  padding-top: 10px;
  --background: #ffffff;

  ion-select {
    width: 100%;
  }
}

ion-datetime-button {
  padding-top: 10px;
}

ion-datetime {
  color: #252539;
}

.image-container {
  position: relative;
  padding-top: 10px;
}

.marker {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

ion-item {
  --background-hover: none;
}
</style>
