import { defineStore } from 'pinia';
import axios from 'axios';
import { formatDate } from '@/composables/formatDate';
import * as Sentry from '@sentry/vue';

function associateClaimsAndPolicies(claims, policies) {
  claims.sort((dateA, dateB) => {
    return new Date(dateB.date_of_loss) - new Date(dateA.date_of_loss);
  });

  for (let policy of policies) {
    const startDate = new Date(policy.inception_date);
    policy.inception_date = formatDate(startDate);

    const expiryDate = new Date(policy.expiry_date);
    policy.expiry_date = formatDate(expiryDate);
  }

  for (let claim of claims) {
    const matchingPolicy = policies.find(
      (policy) => policy.id === claim.policy_id
    );
    const dateOfLoss = new Date(claim.date_of_loss);

    claim.product = matchingPolicy.product;
    claim.policy_no = matchingPolicy.policy_no;
    claim.policy_term = matchingPolicy.term;
    claim.policy_inception_date = matchingPolicy.inception_date;
    claim.policy_expiry_date = matchingPolicy.expiry_date;
    claim.claims_number_this_year = matchingPolicy.claims_number_this_year;
    claim.claims_annual_limit = matchingPolicy.claims_annual_limit;
    claim.policy_status = matchingPolicy.status;
    claim.date_of_loss = formatDate(dateOfLoss);
    claim.link = matchingPolicy.link;
  }

  return { claims, policies };
}

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    isAuthorised: false,
    token: '',
    customer: [],
    vehicle: [],
    policies: [],
    claims: [],
    mileage: '',
    submittedPhotos: [],
    claimantEmail: '',
    dateOfLoss: '',
    countryIncident: '',
    claimLossTypeId: '',
    claimCauseTypeId: '',
    claimCausalPartId: '',
    causeNotes: '',
    additionalQuestions: [],
    questionsAnswers: {},
  }),
  getters: {
    getCustomer(state) {
      return state.customer;
    },
  },
  actions: {
    async fetchCustomer(passcode) {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/signInWithCode`;
        const data = { code: passcode };
        const response = await axios.post(url, data);

        if (response.data.success) {
          this.isAuthorised = true;
        }

        this.token = response.data.token;
        this.customer = response.data.data.Customer;
        this.vehicle = response.data.data.Vehicle;
        const { claims, policies } = associateClaimsAndPolicies(
          response.data.data.Claims,
          response.data.data.Policies
        );
        this.claims = claims;
        this.policies = policies;

        return response;
      } catch (error) {
        if (error.response) {
          Sentry.captureException(error.response.data.error);
          Sentry.captureMessage(error.response.data.error.message);
        } else {
          Sentry.captureException(error);
          Sentry.captureMessage(error.message);
        }
      }
    },
    async fetchAdditionalQuestions(claimLossTypeId) {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/getClaimAdditionalQuestions?claimLossTypeId=${claimLossTypeId}`;
        const response = await axios.get(url, {
          headers: { 'X-Auth-Token': this.token },
        });

        this.additionalQuestions = response.data.data;

        return response;
      } catch (error) {
        if (error.response) {
          Sentry.captureException(error.response.data.error);
          Sentry.captureMessage(error.response.data.error.message);
        } else {
          Sentry.captureException(error);
          Sentry.captureMessage(error.message);
        }
      }
    },
    async fetchClaims() {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/getClaims`;
        const response = await axios.get(url, {
          headers: { 'X-Auth-Token': this.token },
        });

        const { claims, policies } = associateClaimsAndPolicies(
          response.data.data,
          this.policies
        );
        this.claims = claims;
        this.policies = policies;
      } catch (error) {
        if (error.response) {
          Sentry.captureException(error.response.data.error);
          Sentry.captureMessage(error.response.data.error.message);
        } else {
          Sentry.captureException(error);
          Sentry.captureMessage(error.message);
        }

        Sentry.captureException(error);
      }
    },
    async submitClaim(policyId) {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/saveClaim`;
        const data = {
          policy_id: policyId,
          claimant_email: this.claimantEmail || this.customer[0].email,
          date_of_loss: new Date(this.dateOfLoss).toISOString().slice(0, 10),
          claim_loss_type_id: this.claimLossTypeId,
          claim_cause_type_id: this.claimCauseTypeId,
          claim_causal_part_id: this.claimCausalPartId,
          mileage_on_dol: this.mileage,
          cause_notes: this.causeNotes,
          token: this.token,
          questions_answers: Object.entries(this.questionsAnswers).map(
            ([claim_question_id, answer]) => ({
              claim_question_id,
              answer,
            })
          ),
        };
        const response = await axios.post(url, data, {
          headers: { 'X-Auth-Token': this.token },
        });
        const newClaimId = response.data.data.Claims[0].id;

        for (const photo of this.submittedPhotos) {
          await this.saveAttachment(photo, newClaimId);
        }

        await this.resetNewRequest();

        return response;
      } catch (error) {
        if (error.response) {
          Sentry.captureException(error.response.data.error);
          Sentry.captureMessage(
            `${error.response.data.error.message}, PolicyID: ${policyId}`
          );
        } else {
          Sentry.captureException(error);
          Sentry.captureMessage(`${error.message}, PolicyID: ${policyId}`);
        }
      }
    },
    async saveAttachment(photo, newClaimId) {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/saveAttachment`;
        const data = {
          token: this.token,
          data: photo.data,
          claim_id: newClaimId,
          filename: photo.fileName,
        };

        await axios.post(url, data, {
          headers: { 'X-Auth-Token': this.token },
        });
      } catch (error) {
        if (error.response) {
          Sentry.captureException(error.response.data.error);
          Sentry.captureMessage(
            `${error.response.data.error.message}, ClaimID: ${newClaimId}, FileName: ${photo.fileName}`
          );
        } else {
          Sentry.captureException(error);
          Sentry.captureMessage(
            `${error.message}, ClaimID: ${newClaimId}, FileName: ${photo.fileName}`
          );
        }
      }
    },
    async resetNewRequest() {
      this.mileage = '';
      this.submittedPhotos = [];
      this.dateOfLoss = '';
      this.claimLossTypeId = '';
      this.claimCauseTypeId = '';
      this.claimCausalPartId = '';
      this.causeNotes = '';
      this.additionalQuestions = [];
      this.questionsAnswers = {};
    },
  },
  persist: true,
});
