<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="VEHICLE DETAILS."
          description="Please review and confirm your vehicle details below."
        />
      </div>
      <SubmitRequestBreadcrumbs />
      <ion-grid :fixed="true" class="ion-padding">
        <ion-row class="ion-padding-bottom ion-padding-top">
          <ion-col size-md="6" size-xs="12">
            <ion-label>Make</ion-label>
            <ion-item lines="none">
              <ion-input :readonly="true">{{ vehicle.make }}</ion-input>
            </ion-item>
          </ion-col>
          <ion-col size-md="6" size-xs="12">
            <ion-label>Model</ion-label>
            <ion-item lines="none">
              <ion-input :readonly="true">{{ vehicle.model }}</ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding-bottom">
          <ion-col size-md="6" size-xs="12">
            <ion-label>Mileage</ion-label>
            <ion-item lines="none" :class="{ 'ion-invalid': !isValid }">
              <ion-input
                type="number"
                v-model="customerStore.mileage"
              ></ion-input>
              <ion-note slot="error">{{ errorMessageMileage }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-between ion-padding-bottom">
          <ion-col size-md="6" size-xs="12" class="upload-item">
            <ion-item lines="none">
              <ion-label class="ion-text-wrap">
                <h2>Upload photos of the damage to the vehicle</h2>
                <p>
                  To proceed with your request, please click 'Next' to view our
                  photo support before continuing with photo submission.
                </p>
              </ion-label>
            </ion-item>
          </ion-col>
          <ion-col size-md="2" size-xs="12" class="ion-align-self-end">
            <ion-button
              id="next-btn"
              expand="full"
              @click="nextPage(policyId)"
              :style="{ '--background': branding.color_primary }"
              >Next</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
      <div
        id="contact-container"
        :style="{ 'background-color': branding.contact_container_color }"
      >
        <ion-grid :fixed="true" class="ion-padding">
          <ContactCard
            :getInTouchButton="true"
            title="Incorrect Details?"
            description="Please ensure that your details are correct before proceeding. If you notice any errors, get in touch to speak to our team."
          />
        </ion-grid>
      </div>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonNote,
  IonInput,
  IonButton,
} from '@ionic/vue';
import BannerComponent from '@/components/BannerComponent.vue';
import SubmitRequestBreadcrumbs from '@/components/SubmitRequestBreadcrumbs.vue';
import ContactCard from '@/components/ContactCard.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useCustomerStore } from '../../store/customer';
import { useBrandingStore } from '../../store/branding';
import { useRoute, useRouter } from 'vue-router';
import products from '../../composables/products';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const screenWidth = ref(window.innerWidth);
const isValid = ref(true);
const errorMessageMileage = ref('');
const route = useRoute();
const router = useRouter();
const customerStore = useCustomerStore();
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const vehicle = computed(() => {
  return customerStore.vehicle[0];
});
const policyId = computed(() => {
  return route.params.policyId;
});
const product = computed(() => {
  return customerStore.policies.find((policy) => policy.id === policyId.value)
    .product;
});

desktopBannerSrc.value = brandingStore.brand.desktop_banner_6;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_6;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

function validateMileage() {
  if (customerStore.mileage === '') {
    return 'Please enter your mileage';
  }

  return null;
}

function nextPage(policyId) {
  const mileageErrorMessage = validateMileage();
  if (mileageErrorMessage) {
    isValid.value = false;
    errorMessageMileage.value = mileageErrorMessage;
    return;
  }

  isValid.value = true;
  const relatedProduct = products.find((item) =>
    product.value.includes(item.name)
  );

  if (!relatedProduct) {
    router.push({ name: 'submit-photos', params: { policyId } });
    return;
  } else if (relatedProduct.name === 'GAP') {
    router.push({ name: 'request-details', params: { policyId } });
    return;
  }

  if (relatedProduct.hasVideo || relatedProduct.hasPhotoInstructions) {
    router.push({ name: 'photo-instructions', params: { policyId } });
  }
}
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}

ion-item {
  --background: #ffffff;
  padding-top: 10px;
}

.upload-item {
  h2 {
    font-weight: bold;
  }

  p {
    color: #252539;
  }

  ion-item {
    --background: #f6f6f7;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
