<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div class="logo">
        <!-- <img
          src="../../public/img/icons/gardxgroup/GardX_ParentLogo_CMYK.png"
        /> -->
      </div>
      <div class="text-container">
        <h1>PRIVACY POLICY.</h1>

        <p>
          GardX International Ltd is registered in England and Wales under
          Company Number 05465377 with its registered office at 2nd floor, Lake
          House, 2 Port Way, Port Solent, Hampshire PO6 4TY United Kingdom
          (“GardX, We, You and Our”)
        </p>

        <h2>Introduction</h2>

        <p>
          Thank you for purchasing one of our products. We value our customers’
          privacy and have taken great care to ensure the security of your
          transaction.
        </p>

        <p>
          We endeavour to take all reasonable steps to protect your personal
          information. However, we cannot guarantee the security of any data you
          disclose online. You accept the inherent security risks of providing
          information and dealing online over the Internet and will not hold us
          responsible for any breach of security unless this is due to our
          negligence or wilful default.
        </p>

        <h2>The purpose of this notice</h2>

        <p>
          GardX is committed to meeting its obligations under the Data
          Protection Act 2018, the UK GDPR (including the Data Protection,
          Privacy and Electronic Communications Amendments 2019 DPPEC) and the
          Privacy and Electronic Communications Regulations (PECR). GardX will
          strive to observe the law in all collection and processing of subject
          data and will meet any subject access request in compliance with the
          law. GardX will only use data in ways relevant to carrying out its
          legitimate purposes and functions in a way that is not prejudicial to
          the interests of individuals. GardX will take due care in the
          collection and storage of any sensitive data. GardX staff and its
          agents will do their utmost to keep all data accurate, timely and
          secure. This Notice is designed to help you understand what kind of
          information we collect in connection with our products and services
          and how we will process and use this information. In the course of
          providing you with products and services we will collect and process
          information that is commonly known as personal data. This Notice
          describes how we collect, use, share, retain and safeguard personal
          data. This Notice sets out your individual rights; we explain these
          later in the Notice but in summary these rights include your right to
          know what data is held about you, how this data is processed and how
          you can place restrictions on the use of your data.
        </p>

        <h2>What is personal data?</h2>

        <p>
          Personal data is information relating to an identified or identifiable
          natural person. Examples include an individual’s name, age, address,
          date of birth, their gender and contact details. Personal data may
          contain information which is known as special categories of personal
          data. This may be information relating to an individual’s health,
          racial or ethnic origin, political opinions, religious or
          philosophical beliefs, trade union membership, genetic and biometric
          data, or data relating to or sexual orientation. Personal data may
          also contain data relating to criminal convictions and offences. For
          the purposes of safeguarding and processing criminal conviction and
          offence data responsibly, this data is treated in the same manner as
          special categories of personal data, where we are legally required to
          comply with specific data processing requirements.
        </p>

        <h2>Personal data we collect</h2>

        <p>
          In order for us to arrange and administer insurance and guarantees for
          you we will collect and process personal data about you. We will also
          collect your personal data where you request information about our
          services, customer events, promotions and campaigns. We may also need
          to collect personal data relating to others in order to arrange and
          administer insurance and guarantees. In most circumstances, you will
          provide us with this information. Where you disclose the personal data
          of others, you must ensure you are entitled to do so. You may provide
          us with personal data when completing online quote or contact forms,
          when you contact us via the telephone, when writing to us directly or
          where we provide you with paper based forms for completion or we
          complete a form in conjunction with you. We will share your personal
          data within our companies and with business partners. This is normal
          practice within the insurance industry where it is necessary to share
          information in order to place, quantify and underwrite risks, to
          assess overall risk exposure and to process claims. It is also
          necessary to determine the premium payable and to administer our
          business. We also share personal data with authorised third parties,
          this is necessary where we are required to do so by law, where we need
          to administer our business, to quote for, source, place and administer
          your insurances and guarantees including arranging insurance premium
          finance, to perform underwriting activities and to process claims.
          Some examples follow: Insurers; Underwriters; Premium finance
          providers; Credit reference agencies; Debt recovery agencies; Claims
          handling companies; Loss adjusters; Insurance brokers; Reinsurers;
          Regulators. We will collect your personal data when you visit our
          website, where we will collect your unique online electronic
          identifier; this is commonly known as an IP address. We will also
          collect electronic personal data when you first visit our website
          where we will place a small text file that is commonly known as a
          cookie on your computer. Cookies are used to identify visitors and to
          simplify accessibility, and to monitor visitor behaviour when viewing
          website content, navigating our website and when using features. For
          more information, please see our Cookie Policy. We may record your
          communications with us when contacting our customer care, complaints
          and other customer focused functions. We also collect personal data
          through the use of telematics or similar locational tracking services,
          where you have agreed to the use of this particular service. Where we
          collect data directly from you, we are considered to be the controller
          of that data i.e. we are the data controller. Where we use third
          parties to process your data, these parties are known as processors of
          your personal data. Where there are other parties involved in
          underwriting or administering your insurance and guarantees they may
          also process your data in which circumstances we will be a joint data
          controller of your personal data. A data ‘controller’ means the
          individual or organisation which, alone or jointly with others,
          determines the purposes and means of the processing of personal data.
          A data ‘processor’ means the individual or organisation which
          processes personal data on behalf of the controller. As a provider of
          insurance and guarantee services, we will process the following
          categories of data: Personal data such as an individual’s name,
          address, gender, contact details and details of historic claims Data
          relating to insurance fraud If you object to the collection, sharing
          and use of your personal data we may be unable to provide you with our
          products and services. For the purposes of meeting the Data Protection
          Act 2018, the UK GDPR (including the Data Protection, Privacy and
          Electronic Communications Amendments 2019 DPPEC) and the Privacy and
          Electronic Communications Regulations (PECR) territorial scope
          requirements, the United Kingdom is identified as the named territory
          where the processing of personal data takes place, although in the
          respect of claims some data may be processed in the European Economic
          Area (EEA). If you require more information about our insurance and
          guarantee processes or further details on how we collect personal data
          and with whom we share data with, please contact our Data Protection
          Officer by e-mailing dataprotectionofficer@gardx.co.uk.
        </p>

        <h2>Why do we need your personal data?</h2>

        <p>
          We will use your personal data for the performance of our contract
          with you, to quote for and provide you with insurance and guarantee
          products and services, to process claims and renewals, to administer
          your policy and our business, to respond to any requests from you
          about services we provide and to process complaints. We will also use
          your personal data to manage your account, perform statistical
          analysis on the data we collect, for financial planning and business
          forecasting purposes and to develop new and market existing products
          and services. In purchasing our products and services you should
          understand that you are forming a contract with us. If you contact us
          for a quote or request details on the services we provide, we consider
          ourselves as having a legitimate business interest to provide you with
          further information about our services. In some situations, we may
          request your consent to market our products and services to you, to
          share your data or to transfer your data outside the European Economic
          Area. Where we require consent, your rights and what you are
          consenting to will be clearly communicated to you. Where you provide
          consent, you can withdraw this at any time by contacting our Data
          Protection Officer on dataprotectionofficer@gardx.co.uk We will retain
          your personal data at the end of any contractual agreement for a
          period of 7 years. Where you have submitted a claim, we will retain
          your data for a period of 7 years. Where you have requested a quote,
          we will retain your personal data for 6 months,where you have
          contacted us for details of our services and products, we will retain
          your personal data for 3 months. Where you make a complaint we will
          retain the data for 7 years. Where you or law enforcement agencies
          inform us about any active investigation or potential criminal
          prosecution, we will comply with legal requirements when retaining
          this data. The retaining of data is necessary where required for
          contractual, legal or regulatory purposes or for our legitimate
          business interestsfor statistical analysis (profiling) and product
          development and marketing purposes. Sometimes we may need to retain
          your data for longer, for example if we are representing you or
          defending ourselves in a legal dispute or as required by law or where
          evidence exists that a future claim may occur. You should be aware
          that we use automated decision making (services/tools and techniques)
          to check for customer suitability to our products, for example we
          might perform a credit search to check an individual’s solvency and
          credit rating. We also analyse data to identify products and services
          that customers may be interested in, this is commonly known as
          profiling. You have the right to object to the use of profiling
          activities and the use of automated decision making (services/tools
          and techniques). You should also be aware that we may share your data
          with certain agencies to prevent fraud. Please contact our Data
          Protection Officer if you object to the use of, or you have any
          questions relating to the use of, your data, the retention of your
          personal data. You can opt out of receiving marketing services on
          01243 376426 or email us at dataprotectionofficer@gardx.co.uk.
        </p>

        <h2>Product Specification</h2>

        <p>
          The specifications contained on our website are for information
          purposes only as we cannot confirm the exact specifications of the
          Products. If you require any specific information about a product,
          please email us at info@gardx.co.uk.
        </p>

        <h2>Intellectual Property Rights</h2>

        <p>
          Our website is published by GardX and is protected by copyright and
          other intellectual property rights in existence at any time throughout
          the world. All website design, text, graphics, the typographical
          arrangement thereof, and all other material on this website is
          copyright of GardX or their service and technology providers.
          Permission is hereby granted to electronically copy and to print hard
          copy portions of this web site for the sole purpose of using this as
          an information resource for GardX products. Any other use of materials
          on this website, including reproduction for purposes other than the
          above, modification, distribution or republication without the prior
          written permission of GardX is prohibited. The trade marks, logos and
          service marks shown on the GardX web site, unless otherwise specified,
          are the trademarks of GardX. No rights are granted to use any of them
          in any manner whatsoever. Any infringement of GardX's rights will
          result in appropriate legal action being taken against you.
        </p>

        <h2>How does your browser protect you and your data?</h2>

        <p>
          Most modern web browsers, such as Internet Explorer and Firefox,
          support 'secure mode' and standard security protocols. Internet
          Explorer can warn you if the site you are about to send payment
          details to is secure or insecure. To make this happen, select OPTIONS
          on the VIEW menu and then click the SECURITY tab. Once all of the
          warning boxes have been ticked, Internet Explorer will automatically
          alert you each time you send your private details to another site.
          Online security is constantly evolving so, if you are using older
          versions of a web browser, we recommend you update your browser to the
          latest version in order to take full advantage of the improved
          security features.
        </p>

        <h2>Disclaimer and liability</h2>

        <p>
          All express warranties, representations, conditions of any kind or
          other terms implied by statute or common law with respect to our web
          site or the information, content, materials or products included on
          our web site are hereby expressly excluded to the fullest extent
          permitted by law. GardX shall not be liable in any circumstances for
          any direct or indirect, special or consequential loss or damage
          (whether for profit or loss or otherwise) costs, claims, expenses or
          other claims for compensation what so ever, whether caused by the
          acts, omissions or the negligence of GardX, its employees or agents,
          which arise out of or in connection with the use of our web site or
          the information, content materials or products included on our
          website, except in respect of death or personal injury caused by
          GardX's employees' or it's agents' proven negligence. GardX does not
          accept any responsibility or liability for access to or material on,
          any website that is linked from or to this website.
        </p>

        <h2>Misuse Statement</h2>

        <p>
          GardX reserves the right to prevent your usage of our site if you
          misuse the contents contained within this website in any manner. GardX
          is the final arbiter as to what shall constitute misuse and its
          decision will be final.
        </p>

        <h2>Secure Shopping</h2>

        <p>
          We use the SagePay automated online payment service provided by
          SagePay to ensure that your credit card information is treated with
          the highest standards of safety and confidentiality. At no time is
          information about your credit card held on our servers. All
          transaction information passed between us and the SagePay VSP Systems
          is encrypted using 128-bit SSL certificates. No cardholder information
          is ever passed unencrypted and any messages sent to your servers from
          SagePay are signed using MD5 hashing to prevent tampering. You can be
          completely secure in the knowledge that nothing you pass to the
          SagePay servers can be examined, used or modified by any third parties
          attempting to gain access to sensitive information. Once on SagePay
          systems, all sensitive data is secured using the same internationally
          recognised 256-bit encryption standards used by, among others, the US
          Government. The encryption keys are held on state-of-the-art, tamper
          proof systems in the same family as those used to secure VeriSign's
          Global Root certificate, making them all but impossible to extract.
          The data that SagePay holds is extremely secure and SagePay are
          regularly audited by the banks and banking authorities to ensure it
          remains so. If you are still concerned about ordering electronically,
          you can place your order by calling us directly on 01243 376426 from
          9.00 a.m. to 5.30 p.m. (GMT), Monday to Friday.
        </p>

        <h2>Your rights</h2>

        <p>
          Individuals are provided with legal rights governing the use of their
          personal data. These grant individuals the right to understand what
          personal data relating to them is held, for what purpose, how it is
          collected and used, with whom it is shared, where it is located, to
          object to its processing, to have the data corrected if inaccurate, to
          take copies of the data and to place restrictions on its processing.
          Individuals can also request the deletion of their personal data.
          These rights are known as Individual Rights under the Data Protection
          Act 2018 and the UK GDPR. The following list details these rights: The
          right to be informed about the personal data being processed; The
          right of access to your personal data; The right to object to the
          processing of your personal data; The right to restrict the processing
          of your personal data; The right to rectification of your personal
          data; The right to erasure of your personal data; The right to data
          portability (to receive an electronic copy of your personal data);
          Rights relating to automated decision making including profiling.
          Individuals can exercise their Individual Rights at any time. As
          mandated by law we will not charge a fee to process these requests,
          however if your request is considered to be repetitive, wholly
          unfounded and/or excessive, we are entitled to charge a reasonable
          administration fee. In exercising your Individual Rights, you should
          understand that in some situations we may be unable to fully meet your
          request, for example if you make a request for us to delete all your
          personal data, we may be required to retain some data for taxation,
          prevention of crime and for regulatory and other statutory purposes.
          You should understand that when exercising your rights, a substantial
          public or vital interest may take precedence over any request you
          make. In addition, where these interests apply, we are required by law
          to grant access to this data for law enforcement, legal and/or health
          related matters. The flow of data within the insurance sector is
          complex and we ask you to keep this in mind when exercising your
          ‘rights of access’ to your information. Where we may be reliant on
          other organisations to help satisfy your request this may impact on
          timescales. If you require further information on your Individual
          Rights or you wish to exercise your Individual Rights, please contact
          our Data Protection Officer by email dataprotectionofficer@gardx.co.uk
          or writing to Unit 7, Clovelly Business Park, Clovelly Road,
          Southbourne Industrial Estate, Southbourne, Emsworth, Hampshire, PO10
          8PE. United Kingdom.
        </p>

        <h2>Protecting your data</h2>

        <p>
          We will take all appropriate technical and organisational steps to
          protect the confidentiality, integrity, availability and authenticity
          of your data, including when sharing your data within our companies
          and authorised third parties.
        </p>

        <h2>Data Protection Officer</h2>

        <p>
          To comply with our legal obligations and to ensure data privacy and
          protection has appropriate focus within our organisation we have a
          Data Protection Officer who reports to our senior management team. The
          Data Protection Officer’s contact details are as follows: 01243 376
          426 or dataprotectionofficer@gardx.co.uk
        </p>

        <h2>Complaints</h2>

        <p>
          If you are dissatisfied with any aspect of the way in which we process
          your personal data, please contact the Data Protection Officer. You
          also have the right to complain to the UK’s data protection
          supervisory authority, the Information Commissioner’s Office(ICO). The
          ICO may be contacted via its website which is
          https://ico.org.uk/concerns/, by live chat or by calling their
          helpline on 0303 123 1113.
        </p>

        <h2>GardX Group Anti-Slavery and Human Trafficking Statement</h2>

        <p>
          GardX Group Limited are committed to ensuring that there is no modern
          slavery or human trafficking within our business or any element of the
          supply chain. We have put in place an Anti-Slavery and Human
          Trafficking Policy which reflects our commitment to acting ethically
          and with integrity in all of our business relationships and ensuring
          that we have effective systems and controls in place to ensure modern
          slavery and human trafficking is not taking place within our business
          or supply chains, in accordance with The Modern Slavery Act 2015.
        </p>

        <h2>Anti-Bribery and Corruption Statement</h2>

        <p>
          GardX Group is committed to ensuring that their operations and those
          of their subsidiary organisations are conducted in accordance with the
          UK Bribery Act 2010 and the standard s it sets. Compliance with the UK
          Bribery Act 2010 is essential to the operating procedures of GardX
          Group and its subsidiary organisations and therefore the full
          co-operation and commitment of all staff is expected to maintain these
          high standards. It is GardX Group’s policy to conduct business in an
          honest way, and without the use of corrupt practices or acts of
          bribery to obtain any advantage. The GardX Group group of companies is
          committed to ensuring adherence to the highest legal and ethical
          standards, and this is reflected in every aspect of the way in which
          we operate. This is not just a moral issue, but a legal requirement.
          Bribery is a criminal offence in the majority of countries in which
          GardX Group operates. GardX Group attaches the utmost importance to
          this policy and will apply a zero tolerance approach to any acts of
          bribery and corruption by any agents, employees or third-party
          representatives.
        </p>

        <h2>How to contact us</h2>

        <p>
          If you have any questions regarding this Notice, the use of your data
          or your Individual Rights please contact our Data Protection Officer
          at Unit 7, Clovelly Business Park, Clovelly Road, Southbourne
          Industrial Estate, Southbourne, Emsworth, Hampshire, PO10 8PE. United
          Kingdom or by e-mailing dataprotectionofficer@gardx.co.uk or by
          telephoning 01243 376426.
        </p>
        <div class="button-container">
          <ion-button @click="goBack()">Back</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonButton } from '@ionic/vue';
import { useRouter } from 'vue-router';

const router = useRouter();

function goBack() {
  router.go(-1);
}
</script>

<style lang="scss" scoped>
ion-content {
  --ion-background-color: #ffffff;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40%;
  margin: 0 auto;
}

.text-container {
  padding: 20px;

  h1 {
    color: #ef7e31;
    font-size: 40px;
  }

  p {
    text-align: justify;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
</style>
