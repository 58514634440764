<template>
  <ion-card color="light">
    <ion-card-content>
      <ion-grid>
        <ion-row>
          <ion-col size-md="2" size-xs="12" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h3>{{ props.policy.product }}</h3>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="2" size-xs="6" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h4 :style="{ color: branding.color_primary }">
                    Product Number
                  </h4>
                  <p>{{ props.policy.policy_no }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="2" size-xs="6" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h4 :style="{ color: branding.color_primary }">Start Date</h4>
                  <p>{{ props.policy.inception_date }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="2" size-xs="6" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h4 :style="{ color: branding.color_primary }">
                    Expiry Date
                  </h4>
                  <p>{{ props.policy.expiry_date }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="2" size-xs="6">
            <ion-list lines="none">
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h4 :style="{ color: branding.color_primary }">Limits</h4>
                  <p>
                    {{ props.policy.claims_number_this_year }}/{{
                      props.policy.claims_annual_limit
                    }}
                  </p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col class="ion-align-self-center" size-md="2" size-xs="12">
            <ion-button
              v-if="showSupportButton"
              id="support-btn"
              expand="block"
              href="/support"
              :style="{ '--background': branding.color_secondary }"
              >Need Support?</ion-button
            >
            <ion-button
              v-else
              id="submit-request-btn"
              expand="block"
              @click="nextPage(props.policy.id)"
              :style="{ '--background': branding.color_primary }"
              >Submit A Request</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
} from '@ionic/vue';
import { defineProps, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useBrandingStore } from '../store/branding';

const router = useRouter();
const showSupportButton = computed(() => {
  return (
    props.policy.claims_number_this_year === props.policy.claims_annual_limit
  );
});
const props = defineProps({
  policy: {
    type: Object,
    required: true,
  },
});
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;

function nextPage(policyId) {
  router.push({ name: 'personal-details', params: { policyId } });
}
</script>

<style lang="scss" scoped>
ion-card {
  margin: 0 auto;
}

ion-list {
  background: #ffffff;
}

ion-item {
  --background: #ffffff;
}

h3,
h4 {
  font-weight: bold;
}

h4 {
  color: #ef7e31;
}

p {
  color: #252539;
}

@media (min-width: 769px) {
  .bordered-col {
    border-right: 1px solid #f2f2f2; /* Border on the right for large screens */
  }
}
</style>
