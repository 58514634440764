<template>
  <ion-app>
    <NavigationMenu v-show="!showNavMenu" />
    <ion-router-outlet />
  </ion-app>
</template>

<script setup>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import NavigationMenu from './components/NavigationMenu.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const showNavMenu = computed(() => {
  return (
    route.path === '/login' ||
    route.path === '/verify' ||
    route.path === '/privacy-policy'
  );
});
</script>

<style lang="scss">
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(../public/assets/gardxgroup/fonts/Montserrat-Regular.ttf)
      format('truetype');
}
</style>
