<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="PERSONAL DETAILS."
          description="Please review and confirm your personal information below."
        />
      </div>
      <SubmitRequestBreadcrumbs />
      <ion-grid :fixed="true" class="ion-padding">
        <ion-row class="ion-padding-bottom ion-padding-top">
          <ion-col size-md="6" size-sm="8" size-xs="12">
            <ion-label>First Name</ion-label>
            <ion-item lines="none">
              <ion-input :readonly="true">{{
                customer.first_name || ''
              }}</ion-input>
            </ion-item>
          </ion-col>
          <ion-col size-md="6" size-sm="8" size-xs="12">
            <ion-label>Surname</ion-label>
            <ion-item lines="none">
              <ion-input :readonly="true">{{ customer.last_name }}</ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding-bottom">
          <ion-col size-md="6" size-sm="8" size-xs="12">
            <ion-label>Email Address</ion-label>
            <ion-item lines="none" :class="{ 'ion-invalid': !isValid }">
              <ion-input
                :readonly="
                  customer.email !== '' || customerStore.claimantEmail !== ''
                    ? true
                    : false
                "
                >{{
                  customer.email || customerStore.claimantEmail || ''
                }}</ion-input
              >
              <ion-note slot="error">{{ errorMessageEmail }}</ion-note>
            </ion-item>
          </ion-col>
          <ion-col size-md="6" size-sm="8" size-xs="12">
            <ion-label>Address</ion-label>
            <ion-item lines="none">
              <ion-input :readonly="true"
                >{{ customer.address1 }}, {{ customer.address2 }}</ion-input
              >
            </ion-item>
            <ion-item lines="none">
              <ion-input :readonly="true">{{ customer.city }}</ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding-bottom">
          <ion-col size-md="6" size-xs="12">
            <ion-label position="stacked">Postcode</ion-label>
            <ion-item lines="none">
              <ion-input :readonly="true">{{ customer.postal_code }}</ion-input>
            </ion-item>
          </ion-col>
          <ion-col size-md="6" size-xs="12">
            <ion-label>Product Number</ion-label>
            <ion-item lines="none">
              <ion-input :readonly="true">{{ policyNo }}</ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-end">
          <ion-col size-md="2" size-xs="12">
            <ion-button
              id="next-btn"
              expand="block"
              @click="nextPage(policyId)"
              :style="{ '--background': branding.color_primary }"
              >Next</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
      <div
        id="contact-container"
        :style="{ 'background-color': branding.contact_container_color }"
      >
        <ion-grid :fixed="true" class="ion-padding">
          <ContactCard
            :getInTouchButton="true"
            title="Incorrect Details?"
            description="Please ensure that your details are correct before proceeding. If you notice any errors, get in touch to speak to our team."
          />
        </ion-grid>
      </div>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonNote,
  IonButton,
} from '@ionic/vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import BannerComponent from '@/components/BannerComponent.vue';
import SubmitRequestBreadcrumbs from '@/components/SubmitRequestBreadcrumbs.vue';
import ContactCard from '@/components/ContactCard.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { useCustomerStore } from '../../store/customer';
import { useBrandingStore } from '../../store/branding';
import { useRoute, useRouter } from 'vue-router';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const screenWidth = ref(window.innerWidth);
const isValid = ref(true);
const errorMessageEmail = ref('');
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const route = useRoute();
const router = useRouter();
const customerStore = useCustomerStore();
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
const customer = computed(() => {
  return customerStore.customer[0] || {};
});
const policyId = computed(() => {
  return route.params.policyId;
});
const policyNo = computed(() => {
  const policy = customerStore.policies.find((p) => p.id === policyId.value);
  return policy?.policy_no;
});

desktopBannerSrc.value = brandingStore.brand.desktop_banner_6;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_6;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

function validateEmail() {
  if (customerStore.claimantEmail === '' && customer.value.email === '') {
    return 'Please enter a valid email address';
  }

  return null;
}

function nextPage(policyId) {
  const emailErrorMessage = validateEmail();

  if (emailErrorMessage) {
    isValid.value = false;
    errorMessageEmail.value = emailErrorMessage;
    return;
  }

  isValid.value = true;
  router.push({ name: 'vehicle-details', params: { policyId } });
}
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}

ion-item {
  --background: #ffffff;
  padding-top: 10px;
}
</style>
