<template>
  <ion-card color="light">
    <ion-card-content>
      <ion-grid :fixed="true">
        <ion-row>
          <ion-col size-md="3" size-xs="12" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h3>{{ claim.product }}</h3>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="2" size-xs="6" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h4 :style="{ color: branding.color_primary }">
                    Product Number
                  </h4>
                  <p>{{ claim.policy_no }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="2" size-xs="6" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">Status</h4>
                  <p>{{ claim.claim_status }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="2" size-xs="6" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">
                    Request Raised
                  </h4>
                  <p>{{ claim.date_of_loss }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="2" size-xs="6">
            <ion-list lines="none">
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">
                    Request Number
                  </h4>
                  <p>{{ claim.claim_no }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
} from '@ionic/vue';
import { defineProps } from 'vue';
import { useBrandingStore } from '../store/branding';

defineProps({
  claim: {
    type: Object,
    required: true,
  },
});

const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
</script>

<style lang="scss" scoped>
h3 {
  font-weight: bold;
}

ion-card {
  margin: 0 auto;
}

h4 {
  color: #ef7e31;
  font-weight: bold;
}

p {
  color: #252539;
}

ion-item {
  --background: #ffffff;
}

ion-list {
  background: #ffffff;
}

@media (min-width: 769px) {
  .bordered-col {
    border-right: 1px solid #f2f2f2;
  }
}
</style>
