<template>
  <ion-card color="light" class="ion-text-center ion-align-items-end">
    <ion-card-header>
      <ion-card-title>{{ title }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>{{ content }}</ion-card-content>
    <div>
      <ion-button
        :href="path"
        :style="{ '--background': branding.color_primary }"
        >{{ buttonLabel }}</ion-button
      >
    </div>
  </ion-card>
</template>

<script setup>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from '@ionic/vue';
import { defineProps } from 'vue';
import { useBrandingStore } from '../store/branding';

defineProps({
  title: String,
  content: String,
  buttonLabel: String,
  path: String,
});

const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
</script>

<style lang="scss" scoped>
ion-card {
  height: 260px;
  padding: 10px;

  ion-card-title {
    font-size: 18px;
  }

  p {
    color: #252539;
  }
}
</style>
