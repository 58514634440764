<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div class="container">
        <div class="passcode-container">
          <VerifyForm />
        </div>
        <div class="contact-container">
          <p>Didn't receive your code? Call us</p>
          <a href="tel:02039813580">020 3981 3580</a>
        </div>
        <div class="privacy-policy">
          <a :href="privacyPolicyLink">Privacy Policy</a>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent } from '@ionic/vue';
import { ref } from 'vue';
import VerifyForm from '@/components/VerifyForm.vue';
import { useBrandingStore } from '../store/branding';

const brandingStore = useBrandingStore();

const privacyPolicyLink = ref('');
privacyPolicyLink.value = brandingStore.brand.policy;
</script>

<style lang="scss" scoped>
ion-content {
  --ion-background-color: #252539;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.passcode-container,
.contact-container,
.privacy-policy {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.contact-container {
  a {
    text-decoration: none;
    font-weight: bold;
  }

  p {
    color: #fff;
  }
}

.privacy-policy {
  margin-top: 50px;

  a {
    font-size: small;
  }
}

a {
  color: #fff;
}
</style>
