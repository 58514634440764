<template>
  <ion-page>
    <ion-content class="ion-padding">
      <!-- <div class="logo">
        <img
          src="../../public/img/icons/gardxgroup/GardX_ParentLogo_CMYK.png"
        />
      </div> -->
      <div class="text-container">
        <h1>TERMS AND CONDITIONS.</h1>

        <p>
          This legal notice applies to the entire contents of this website under
          the domain name www.gardxgroup.co.uk ("the Website") and to any
          correspondence by e-mail or telephone between us and you. Please note
          that telephone calls to GardX may be recorded for training and
          security purposes.
        </p>

        <p>
          Please read these terms carefully before using this Website. Using
          this Website indicates that you accept these terms regardless of
          whether or not you choose to place an order with us. If you do not
          accept these terms, do not use this Website. This notice is issued by
          GardX Group Limited.
        </p>

        <h2>1. Information about Us</h2>

        <li>
          www.gardxgroup.com is a site supplied by GardX Group Limited ("We") is
          registered in England and Wales under Company Number 4951862 with its
          registered office at Unit 7, Clovelly Business Park, Clovelly Road,
          Southbourne Industrial Estate, Southbourne, Emsworth, Hampshire, PO10
          8PE. United Kingdom.
        </li>

        <h2>2. Service Availability</h2>

        <li>
          Our site is intended for use by people resident in the United Kingdom.
          We do not accept offers from individuals outside the United Kingdom.
        </li>

        <h2>3. Your Status</h2>

        <li>
          By placing an order through our site, you warrant that you are:<br />
          legally capable of entering into binding contracts;<br />
          at least 18 years old;<br />
          and resident in the United Kingdom.
        </li>

        <h2>4. Products</h2>

        <li>
          We warrant that all Products promoted for sale through this site are
          of satisfactory quality and reasonably fit for all the purposes for
          which Products of the kind are commonly supplied. Specifications on
          this website of the Products to be sold are not intended to be binding
          and are intended only to give a general description of the Products.
          In all other respects, and to the maximum extent permissible, we make
          no other warranties or promises about the Products, and any implied
          warranties are excluded.
        </li>

        <h2>5. Availability</h2>

        <li>
          All Products offered for sale are subject to availability. We regret
          that occasionally it may be impossible to complete an order if stock
          runs out or is withdrawn for any reason. But if there is a problem we
          will let you know as soon as we can.
        </li>

        <h2>6. Ordering</h2>

        <li>
          We will treat each order for Products, which is confirmed when you
          click on the ‘NEXT' button on the Order Confirmation Screen, as an
          offer by you to purchase the Products subject to these terms and
          conditions. After placing an order you will be assigned an Order
          Number, which will be displayed on the screen. Please note that this
          does not mean that your order has been accepted. Your order
          constitutes an offer to us to buy a Product. All orders are subject to
          acceptance by us and we will confirm such acceptance, after payment by
          you has been successful, by notifying you that your order for GardX
          merchandise has been successful and stating a unique Reference Number
          on the ("the Confirmation Page"). The contract between us will only be
          formed when you see the Confirmation Page. The Contract will relate
          only to those Products we have confirmed on the Confirmation Page.
        </li>

        <h2>7. Price and Payment</h2>

        <li>
          The price of any Products will be as quoted on our site from time to
          time, except in cases of obvious error. These prices include VAT but
          exclude delivery costs, which will be added to the total amount due.
          All prices displayed on the website are quoted in UK Pounds Sterling
          and must be paid in full, including delivery charges. Prices are
          liable to change at any time. Our site contains a large number of
          Products and it is always possible that, despite our best efforts,
          some of the Products listed on our site may be incorrectly priced. If
          a Product's correct price is lower than the price stated on our
          website we will reimburse you the difference in price between the
          price you paid and the correct price. If a Product's correct price is
          higher than the price stated on our site, we will normally, at our
          discretion, either contact you for instructions before dispatching the
          Product, or reject your order and notify you of such rejection.
        </li>

        <li>
          We are under no obligation to provide the Product to you at the
          incorrect price, even after we have sent you a Confirmation Email, if
          the pricing error is obvious and unmistakable and could have
          reasonably been recognised by you as a miss-pricing. Payment may be
          made by any of the methods indicated on our website. We do not offer
          any credit facilities, so we will not deliver Products to you if you
          do not pay for your order in full at the time of ordering.
        </li>

        <h2>8. Delivery</h2>

        <li>
          Delivery shall be made to the delivery address supplied by you at the
          time of ordering. While we endeavour to deliver within the times
          specified, any times quoted for delivery are approximate only and we
          shall not be liable for any delay in delivery of the Products
          howsoever caused. Risk of damage to or loss of the Products shall pass
          to you at the time of delivery to the specified delivery address.
          Ownership of the Products will only pass to you when we receive full
          payment of all sums due in respect of the Products, including delivery
          charges.
        </li>
        <li>
          Please note aerosol cans can only be delivered to UK Mainland
          locations.
        </li>

        <h2>9. Returns</h2>

        <li>
          We want you to be totally satisfied with your purchase. If for any
          reason you are not entirely happy with your order we will refund the
          value of the Products supplied provided that you inform us within 10
          days, beginning on the day after you received the Products. In this
          case, you will receive a full refund of the price paid for the
          Products in accordance with our Refunds Policy, minus the delivery
          charge. Products must be returned, together with the delivery note, to
          us in perfect condition to the address specified on the delivery note.
        </li>
        <li>
          To cancel your contract with us for the Products you must, within the
          10 days, beginning on the day after you received the Product:<br />
          contact us via a method explained on the Contact Us page of your
          decision to return the Products;<br />
          return the Products and delivery note to us, immediately, and in the
          same condition in which you received the Products;<br />
          and return the Products at your own cost and risk.
        </li>

        <h2>10. Damaged Products or Incorrect Deliveries</h2>

        <li>
          Our warranty contained in 'Products' above is subject to the following
          conditions:<br />
          the Products must be stored in appropriate conditions; if you do not
          store them in appropriate conditions we cannot be held responsible for
          faults or problems that arise as a result;<br />
          any claim by you in respect of any shortages and/or defects apparent
          on delivery in the Products must be notified to us by you within 10
          days of the delivery date, stating the nature of the shortage/defect
          and quoting the order reference number; failure to notify us, within
          10 days of the delivery date, will deem you to have accepted the
          Products. In some cases we may require you to return the defective
          Products to us at its cost. We will examine the returned Product and
          will, where appropriate, notify you of your refund or replacement
          Product via email or telephone within a reasonable period of time.
        </li>
        <li>
          Where you make a valid claim in respect of any shortages or defects
          and we are liable for the claim, we can, at our option, make up the
          shortages or replace the Products free of charge, or refund the price
          of the Products in question to you. In addition for defective Products
          we will refund you reasonable costs incurred for postage of the
          Product, but we shall have no further liability to you. If you do not
          notify us accordingly, we shall have no liability for any defects and
          you shall be bound to pay for the Products.
        </li>

        <h2>11. Limitation of liability</h2>

        <li>
          The limit of our liability to you is the making up of any shortfall,
          replacement, refund of the price of the Products, and, in the case of
          defective Products, refunding the delivery costs, as provided for in
          Payment & Delivery. We are not responsible to you to any greater
          extent and in particular we are not liable to you for any indirect or
          consequential loss (including without limitation financial loss such
          as loss of profit or otherwise) which you may incur for whatever
          reason, as a consequence of our failure to comply with these terms and
          conditions. This clause does not include or limit in any way our
          liability:<br />
          for death or personal injury;<br />
          under section 2(3) of the Consumer Protection Act 1987;<br />
          for fraud or fraudulent misrepresentation.
        </li>

        <h2>12. Events beyond our reasonable control</h2>

        <li>
          We will not be held responsible for any delay or failure to comply
          with our obligations under these conditions if the delay or failure
          arises from any cause which is beyond our reasonable control. This
          condition does not affect your statutory rights.
        </li>

        <h2>13. Transfer of Rights and Obligations</h2>

        <li>
          The contract between you and us is binding on you and us and on our
          respective successors and assigns. You may not transfer, assign or
          otherwise dispose of a contract, or any of your rights or obligations
          arising under it, without our prior written consent. We may transfer,
          assign, charge, sub-contract or otherwise dispose of a contract, or
          any of our rights or obligations arising under it, at any time during
          the term of the contract.
        </li>

        <h2>14. Waiver</h2>

        <li>
          If we fail, at any time during the term of a contract, to insist upon
          strict performance of any of your obligations under the contract or
          any of these terms and conditions, or if we fail to exercise any of
          the rights or remedies to which we are entitled under the contract,
          this shall not constitute a waiver of such rights or remedies and
          shall not relieve you from compliance with such obligations. A waiver
          by us of any default shall not constitute a waiver of any subsequent
          default. No waiver by us of any of these terms and conditions shall be
          effective unless it is expressly stated to be a waiver and is
          communicated to you in writing.
        </li>

        <h2>15. Our Right to vary these Terms and Conditions</h2>

        <li>
          We have the right to revise and amend these terms and conditions from
          time to time. You will be subject to the policies and terms and
          conditions in force at the time that you order Products from us,
          unless any change to those policies or these terms and conditions is
          required to be made by law or governmental authority, or we notify you
          of the change to those policies or these terms and conditions before
          we send you the Confirmation Email (in which case we have the right to
          assume that you have accepted the change to the terms and conditions,
          unless you notify us to the contrary within 10 days of receipt by you
          of the Products).
        </li>

        <h2>16. Choice of Law and Jurisdiction</h2>

        <li>
          These Terms shall be governed by and construed in accordance with
          English Law and you agree to submit to the exclusive jurisdiction of
          the English courts.
        </li>
        <div class="button-container">
          <ion-button @click="goBack()">Back</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonButton } from '@ionic/vue';
import { useRouter } from 'vue-router';

const router = useRouter();

function goBack() {
  router.go(-1);
}
</script>

<style lang="scss" scoped>
ion-content {
  --ion-background-color: #ffffff;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40%;
  margin: 0 auto;
}

.text-container {
  padding: 20px;

  h1 {
    color: #ef7e31;
    font-size: 40px;
  }

  p {
    text-align: justify;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
</style>
