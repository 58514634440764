<template>
  <ion-card color="light">
    <ion-card-content>
      <ion-grid :fixed="true">
        <ion-row>
          <ion-col size-md="3" size-xs="12" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h3>{{ policy.product }}</h3>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="3" size-xs="6" class="bordered-col">
            <ion-list lines="none">
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h4 :style="{ color: branding.color_primary }">
                    Product Number
                  </h4>
                  <p>{{ policy.policy_no }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label class="ion-text-wrap">
                  <h4 :style="{ color: branding.color_primary }">
                    Product Status
                  </h4>
                  <p>{{ policy.status }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">
                    Expiry Date
                  </h4>
                  <p>{{ policy.expiry_date }}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="3" size-xs="6">
            <ion-list lines="none">
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">
                    Product Term
                  </h4>
                  <p>{{ policy.term }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">Start Date</h4>
                  <p>{{ policy.inception_date }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h4 :style="{ color: branding.color_primary }">Limits</h4>
                  <p>
                    {{ policy.claims_number_this_year }}/{{
                      policy.claims_annual_limit
                    }}
                  </p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col class="ion-align-self-center" size-md="3" size-xs="12">
            <ion-button
              id="view-document-btn"
              expand="full"
              @click="viewDocument(policy)"
              :style="{ '--background': branding.color_secondary }"
              >View Product Documents</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
} from '@ionic/vue';
import { defineProps } from 'vue';
import { useBrandingStore } from '../store/branding';

defineProps({
  policy: {
    type: Object,
    required: true,
  },
});

const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;

function viewDocument(policy) {
  window.open(policy.link, '_blank');
}
</script>

<style lang="scss" scoped>
h3 {
  font-weight: bold;
}

h4 {
  color: #ef7e31;
  font-weight: bold;
}

p {
  color: #252539;
}

ion-card {
  margin: 0 auto;
}

ion-item {
  --background: #ffffff;
}

ion-list {
  background: #ffffff;
}

@media (min-width: 769px) {
  .bordered-col {
    border-right: 1px solid #f2f2f2;
  }
}
</style>
