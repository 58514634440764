import { defineStore } from 'pinia';
import axios from 'axios';
import { ref } from 'vue';
import * as Sentry from '@sentry/vue';

export const useBrandingStore = defineStore('branding', {
  state: () => ({
    brand: ref({}),
    brandCSS: ref({}),
  }),
  actions: {
    async fetchBrand(brandingDomain) {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/getBrand?domain=${brandingDomain}`;
        const response = await axios.get(url);

        this.brand = response.data.data;
      } catch (error) {
        if (error.response) {
          Sentry.captureException(error.response.data.error.message);
          Sentry.captureMessage(error.response.data.error.message);
        } else {
          Sentry.captureException(error);
          Sentry.captureMessage(error.message);
        }
      }
    },
    async fetchBrandingCSS(brandingDomain) {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/getBrandingCSS?domain=${brandingDomain}`;
        const response = await axios.get(url);

        response.data.data.forEach((item) => {
          this.brandCSS[item.css_property] = item.css_value;
        });
      } catch (error) {
        if (error.response) {
          Sentry.captureException(error.response.data.error);
          Sentry.captureMessage(error.response.data.error.message);
        } else {
          Sentry.captureException(error);
          Sentry.captureMessage(error.message);
        }
      }
    },
  },
});
