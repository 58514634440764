<template>
  <ion-card color="light">
    <ion-grid :fixed="true" class="ion-padding">
      <ion-col>
        <ion-row class="ion-justify-content-center">
          <img :src="icon" />
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-card-header class="ion-text-center">
            <ion-card-title>{{ title }}</ion-card-title>
            <ion-card-content>{{ content }}</ion-card-content>
          </ion-card-header>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-button
            :href="path"
            expand="block"
            :class="{
              'button-full-width': isMobile,
              'button-partial-width': !isMobile,
            }"
            :style="{ '--background': branding.color_primary }"
            >{{ buttonLabel }}</ion-button
          >
        </ion-row>
      </ion-col>
    </ion-grid>
  </ion-card>
</template>

<script setup>
import {
  IonCard,
  IonGrid,
  IonCol,
  IonRow,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from '@ionic/vue';
import { defineProps, ref, computed, onMounted, onUnmounted } from 'vue';
import { useBrandingStore } from '../store/branding';

defineProps({
  icon: String,
  title: String,
  content: String,
  buttonLabel: String,
  path: String,
});

const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
img {
  max-width: 30%;
}

@media (max-width: 768px) {
  .button-full-width {
    width: 60%;
  }
}

@media (min-width: 769px) {
  .button-partial-width {
    width: 50%;
  }

  ion-card {
    height: 315px;
  }
}
</style>
