import { createRouter, createWebHistory } from '@ionic/vue-router';
import LoginPage from '@/pages/LoginPage.vue';
import VerifyPage from '@/pages/VerifyPage.vue';
import Dashboard from '@/pages/DashboardPage.vue';
import ProductsPage from '@/pages/ProductsPage.vue';
import SubmitRequestPage from '@/pages/SubmitRequestPage.vue';
import PersonalDetailsPage from '@/pages/submit-request-pages/PersonalDetailsPage.vue';
import VehicleDetailsPage from '@/pages/submit-request-pages/VehicleDetailsPage.vue';
import PhotoInstructionsPage from '@/pages/submit-request-pages/PhotoInstructionsPage.vue';
import SubmitPhotosPage from '@/pages/submit-request-pages/SubmitPhotosPage.vue';
import RequestDetailsPage from '@/pages/submit-request-pages/RequestDetailsPage.vue';
import AdditionalQuestionsPage from '@/pages/submit-request-pages/AdditionalQuestionsPage.vue';
import ThankYouPage from '@/pages/submit-request-pages/ThankYouPage.vue';
import RequestsPage from '@/pages/RequestsPage.vue';
import SupportPage from '@/pages/SupportPage.vue';
import FAQsPage from '@/pages/support-pages/FAQsPage.vue';
import VideoSupportPage from '@/pages/support-pages/VideoSupportPage.vue';
import GetInTouchPage from '@/pages/support-pages/GetInTouchPage.vue';
import PrivacyPolicyPage from '@/pages/PrivacyPolicyPage.vue';
import TermsConditionsPage from '@/pages/TermsConditionsPage.vue';
import { useCustomerStore } from '../store/customer';
import { useBrandingStore } from '../store/branding';
import * as Sentry from '@sentry/vue';

const routes = [
  {
    path: '/',
    redirect: 'login',
    component: LoginPage,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { authorised: false },
  },
  {
    path: '/verify',
    name: 'verify',
    component: VerifyPage,
    meta: { authorised: false },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { authorised: true },
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsPage,
    meta: { authorised: true },
  },
  {
    path: '/submit-request',
    name: 'submit-request',
    component: SubmitRequestPage,
    meta: { authorised: true },
  },
  {
    path: '/submit-request/:policyId/personal-details',
    name: 'personal-details',
    component: PersonalDetailsPage,
    meta: { authorised: true },
  },
  {
    path: '/submit-request/:policyId/vehicle-details',
    name: 'vehicle-details',
    component: VehicleDetailsPage,
    meta: { authorised: true },
  },
  {
    path: '/submit-request/:policyId/photo-instructions',
    name: 'photo-instructions',
    component: PhotoInstructionsPage,
    meta: { authorised: true },
  },
  {
    path: '/submit-request/:policyId/submit-photos',
    name: 'submit-photos',
    component: SubmitPhotosPage,
    meta: { authorised: true },
  },
  {
    path: '/submit-request/:policyId/request-details',
    name: 'request-details',
    component: RequestDetailsPage,
    meta: { authorised: true },
  },
  {
    path: '/submit-request/:policyId/:claimLossTypeId/additional-questions',
    name: 'additional-questions',
    component: AdditionalQuestionsPage,
    meta: { authorised: true },
  },
  {
    path: '/submit-request/thank-you',
    name: 'thank-you',
    component: ThankYouPage,
    meta: { authorised: true },
  },
  {
    path: '/requests',
    name: 'requests',
    component: RequestsPage,
    meta: { authorised: true },
  },
  {
    path: '/support',
    name: 'support',
    component: SupportPage,
    meta: { authorised: true },
  },
  {
    path: '/support/faqs',
    name: 'faqs',
    component: FAQsPage,
    meta: { authorised: true },
  },
  {
    path: '/support/video-support',
    name: 'video-support',
    component: VideoSupportPage,
    meta: { authorised: true },
  },
  {
    path: '/support/get-in-touch',
    name: 'get-in-touch',
    component: GetInTouchPage,
    meta: { authorised: true },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyPage,
    meta: { authorised: false },
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: TermsConditionsPage,
    meta: { authorised: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const customerStore = useCustomerStore();
  const brandingStore = useBrandingStore();

  if (Object.keys(brandingStore.brand).length === 0) {
    try {
      const queryParams = to.query;
      const domain = queryParams.domain || window.location.hostname;

      await Promise.all([
        brandingStore.fetchBrand(domain),
        brandingStore.fetchBrandingCSS(domain),
      ]);
    } catch (error) {
      Sentry.captureException(error);
      Sentry.captureMessage(error.message);
    }
  }

  if (customerStore.isAuthorised || !to.meta.authorised) {
    return next();
  }

  next('/login');
});

export default router;
