<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="MY PRODUCTS."
          description="View and manage your products in one place. Get instant access to
            your details and download documents with ease."
        />
      </div>
      <ion-grid :fixed="true" class="ion-padding">
        <h2>Your Product Details</h2>
        <ion-row
          v-for="policy in policies"
          :key="policy.id"
          class="ion-justify-content-center"
        >
          <ion-col class="ion-padding-top ion-padding-bottom">
            <ion-accordion-group>
              <ion-accordion>
                <ion-item slot="header" color="light">
                  <ion-label>{{ policy.product }}</ion-label>
                </ion-item>
                <div class="ion-padding" slot="content">
                  <ion-grid>
                    <ion-row class="ion-justify-content-center">
                      <ion-col size-md="4" size-xs="6" class="bordered-col">
                        <ion-list lines="none">
                          <ion-item>
                            <ion-label>
                              <h4 :style="{ color: branding.color_primary }">
                                Product Term
                              </h4>
                              <p>{{ policy.term }}</p>
                            </ion-label>
                          </ion-item>
                          <ion-item>
                            <ion-label>
                              <h4 :style="{ color: branding.color_primary }">
                                Start Date
                              </h4>
                              <p>{{ policy.inception_date }}</p>
                            </ion-label>
                          </ion-item>
                          <ion-item>
                            <ion-label>
                              <h4 :style="{ color: branding.color_primary }">
                                Limits
                              </h4>
                              <p>
                                {{ policy.claims_number_this_year }}/{{
                                  policy.claims_annual_limit
                                }}
                              </p>
                            </ion-label>
                          </ion-item>
                          <ion-item>
                            <ion-label class="ion-text-wrap">
                              <h4 :style="{ color: branding.color_primary }">
                                Product Number
                              </h4>
                              <p>{{ policy.policy_no }}</p>
                            </ion-label>
                          </ion-item>
                        </ion-list>
                      </ion-col>
                      <ion-col size-md="6" size-xs="6">
                        <ion-list lines="none">
                          <ion-item>
                            <ion-label>
                              <h4 :style="{ color: branding.color_primary }">
                                Product Status
                              </h4>
                              <p>{{ policy.status }}</p>
                            </ion-label>
                          </ion-item>
                          <ion-item>
                            <ion-label>
                              <h4 :style="{ color: branding.color_primary }">
                                Expiry Date
                              </h4>
                              <p>{{ policy.expiry_date }}</p>
                            </ion-label>
                          </ion-item>
                          <ion-item lines="none">
                            <ion-label class="ion-text-wrap">
                              <h4 :style="{ color: branding.color_primary }">
                                Product Documents
                              </h4>
                              <p>
                                Instantly download or view your product overview
                                packs below.
                              </p>
                              <ion-button
                                id="view-document-btn"
                                @click="viewDocument(policy)"
                                :style="{
                                  '--background': branding.color_primary,
                                }"
                                >Download</ion-button
                              >
                            </ion-label>
                          </ion-item>
                        </ion-list>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
                <div
                  slot="content"
                  class="ion-padding submit-request-content"
                  :style="{ background: branding.color_secondary }"
                >
                  <ion-grid>
                    <ion-row class="ion-justify-content-end">
                      <ion-col size-md="5" size-sm="6" size-xs="12">
                        <p>
                          Click the button to begin your step-by-step request.
                        </p>
                      </ion-col>
                      <ion-col size-md="2" size-sm="6" size-xs="12">
                        <ion-button
                          expand="full"
                          href="/submit-request"
                          :style="{ '--background': branding.color_primary }"
                          >Submit A Request</ion-button
                        >
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
              </ion-accordion>
            </ion-accordion-group>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div
        id="contact-container"
        :style="{ 'background-color': branding.contact_container_color }"
      >
        <ion-grid :fixed="true" class="ion-padding">
          <ContactCard
            :getInTouchButton="true"
            :seeFAQ="true"
            title="Can't find what you're looking for?"
            description="Please check out our FAQs or get in touch with the team."
          />
        </ion-grid>
      </div>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonAccordionGroup,
  IonAccordion,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
} from '@ionic/vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useCustomerStore } from '../store/customer';
import { useBrandingStore } from '../store/branding';
import BannerComponent from '@/components/BannerComponent.vue';
import ContactCard from '@/components/ContactCard.vue';
import FooterComponent from '@/components/FooterComponent.vue';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const customerStore = useCustomerStore();
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
const policies = computed(() => {
  return customerStore.policies;
});

desktopBannerSrc.value = brandingStore.brand.desktop_banner_4;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_4;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

function viewDocument(policy) {
  const policyDocumentUrl = customerStore.policies.find(
    (p) => p.id === policy.id
  ).link;
  window.open(policyDocumentUrl, '_blank');
}
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

h4 {
  color: #ef7e31;
  font-weight: bold;
}

p {
  color: #252539;
}

ion-accordion-group {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

div[slot='content'] {
  background: #ffffff;
}

ion-item {
  --background: #ffffff;
}

ion-item[slot='header'] {
  font-weight: bold;
}

ion-list {
  background: #ffffff;
}

.submit-request-content {
  p {
    color: #ffffff;
  }
}

@media (min-width: 769px) {
  .bordered-col {
    border-right: 1px solid #f2f2f2;
  }
}
</style>
