export default [
  {
    name: 'Tyre',
    hasVideo: true,
    hasPhotoInstructions: true,
    video: 'Wheels_Photo_Guide',
    desktopBanner: 'Photo_Guide_Tyre',
    mobileBanner: 'Photo_Guide_Tyre_Mobile',
  },
  {
    name: 'Wheel',
    hasVideo: true,
    hasPhotoInstructions: true,
    video: 'Wheels_Photo_Guide',
    desktopBanner: 'Photo_Guide_Tyre',
    mobileBanner: 'Photo_Guide_Tyre_Mobile',
  },
  {
    name: 'Alloy',
    hasVideo: true,
    hasPhotoInstructions: true,
    video: 'Alloy_Photo_Guide',
    desktopBanner: 'Photo_Guide_Alloy',
    mobileBanner: 'Photo_Guide_Alloy_Mobile',
  },
  {
    name: 'C.A.R.S',
    hasVideo: true,
    hasPhotoInstructions: true,
    video: 'CARS_Photo_Guide',
    desktopBanner: 'Photo_Guide_CARS',
    mobileBanner: 'Photo_Guide_CARS_Mobile',
  },
  {
    name: 'Cosmetic',
    hasVideo: true,
    hasPhotoInstructions: true,
    video: 'CARS_Photo_Guide',
    desktopBanner: 'Photo_Guide_CARS',
    mobileBanner: 'Photo_Guide_CARS_Mobile',
  },
  {
    name: 'GAP',
    hasVideo: false,
    hasPhotoInstructions: false,
  },
];
