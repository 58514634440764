<template>
  <ion-modal ref="modal" :trigger="trigger">
    <ion-content class="ion-padding">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button fill="clear" @click="dismiss()">
            <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <div class="faq-container">
        <h2>{{ faqTitle }}</h2>
        <p>{{ faqAnswer }}</p>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import {
  IonModal,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
} from '@ionic/vue';
import { ref, defineProps } from 'vue';
import { closeOutline } from 'ionicons/icons';

const modal = ref(null);

defineProps({
  trigger: String,
  faqTitle: String,
  faqAnswer: String,
});

function dismiss() {
  modal.value.$el.dismiss();
}
</script>

<style lang="scss" scoped>
.faq-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
}
</style>
