<template>
  <ion-page>
    <ion-content>
      <BannerComponent
        :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
        :title="
          customer && customer.first_name
            ? customer.first_name.toUpperCase() +
              (customer.first_name.endsWith('s') ? '\'' : '\'S') +
              ' DASHBOARD.'
            : 'GUEST DASHBOARD.'
        "
        subtitle="Welcome to your account."
        :description="isMobile ? mobileDesc : desktopDesc"
        :dashboard-list="isMobile ? false : true"
      />
      <ion-grid :fixed="true" class="ion-padding">
        <h2>Your Details</h2>
        <ion-row>
          <ion-col>
            <DetailsCard :customer="customer" :vehicle="vehicle" />
          </ion-col>
        </ion-row>
        <h2>Your Product Details</h2>
        <div v-if="policies.length > 0">
          <ion-row v-for="(policy, index) in policies" :key="policy.id">
            <ion-col v-if="index < 2">
              <ProductDetailsCard :policy="policy" />
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-end">
            <ion-col size-md="2" size-xs="12">
              <ion-button
                expand="full"
                href="/products"
                :style="{ '--background': branding.color_secondary }"
                >View all products</ion-button
              >
            </ion-col>
          </ion-row>
        </div>
        <p v-else>No registered products</p>
        <h2>Requests In Progress</h2>
        <div v-if="claims.length > 0">
          <ion-row v-for="(claim, index) in claims" :key="claim.id">
            <ion-col v-if="index < 2">
              <RequestsCard :claim="claim" />
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-end">
            <ion-col size-md="2" size-xs="12">
              <ion-button
                expand="full"
                href="/requests"
                :style="{ '--background': branding.color_primary }"
                >View all requests</ion-button
              >
            </ion-col>
          </ion-row>
        </div>
        <p v-else>No requests in progress</p>
      </ion-grid>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from '@ionic/vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import BannerComponent from '@/components/BannerComponent.vue';
import DetailsCard from '@/components/DetailsCard.vue';
import ProductDetailsCard from '@/components/ProductDetailsCard.vue';
import RequestsCard from '@/components/RequestsCard.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { useCustomerStore } from '../store/customer';
import { useBrandingStore } from '../store/branding';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const mobileDesc = ref(
  'Have peace of mind when you need it most. Take the hassle out of managing your products with your new easy to use account.'
);
const desktopDesc = ref(
  "Have peace of mind when you need it most, see what's relevant to you and have support from a team that's committed to your needs. Take the hassle out of managing your products with your new easy-to-use platform."
);
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const customerStore = useCustomerStore();
const customer = computed(() => {
  return customerStore.customer[0] || {};
});
const vehicle = computed(() => {
  return customerStore.vehicle[0];
});
const policies = computed(() => {
  return customerStore.policies;
});
const claims = computed(() => {
  const policies = customerStore.policies;
  const claims = customerStore.claims;

  for (let claim of claims) {
    const matchingPolicy = policies.find(
      (policy) => policy.id === claim.policy_id
    );
    claim.product = matchingPolicy.product;
    claim.policy_no = matchingPolicy.policy_no;
  }

  return claims;
});
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
desktopBannerSrc.value = brandingStore.brand.desktop_banner_1;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_1;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

h2 {
  padding-top: 20px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}
</style>
