<template>
  <ion-modal ref="modal" trigger="callback-modal" backdrop-dismiss="false">
    <ion-content class="ion-padding">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button fill="clear" @click="dismiss()">
            <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <div class="callback-form">
        <h2>Request A Call Back</h2>
        <p>
          Please email
          <a
            href="mailto:assureservices@gardxgroup.com?subject=Request A Call Back"
            >assureservices@gardxgroup.com</a
          >
          with your Full Name, Email, Telephone, Vehicle Registration and the
          preferred date/time for a call back and a member of our team will get
          in touch with you at a time which is convenient to you.
        </p>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import {
  IonModal,
  IonContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/vue';
import { ref, defineProps } from 'vue';
import { closeOutline } from 'ionicons/icons';

const modal = ref(null);

defineProps({
  trigger: String,
});

function dismiss() {
  modal.value.$el.dismiss();
}
</script>

<style lang="scss" scoped>
.callback-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;

  h2 {
    padding-left: 10px;
  }

  p {
    padding-left: 10px;
  }

  a {
    text-decoration: none;
    color: #252539;
  }
}
</style>
