<template>
  <ion-page>
    <ion-content>
      <div class="banner-container">
        <BannerComponent
          :src="isMobile ? mobileBannerSrc : desktopBannerSrc"
          title="VEHICLE DETAILS."
          description="Please review and confirm your vehicle details below."
        />
      </div>
      <SubmitRequestBreadcrumbs />
      <ion-grid :fixed="true" class="ion-padding">
        <ion-row class="ion-padding-bottom ion-padding-top">
          <ion-col>
            <ion-card color="light">
              <ion-grid>
                <ion-row class="ion-align-items-center">
                  <ion-col size-md="6" size-sm="8" size-xs="12">
                    <ion-card-header>
                      <ion-card-title> How to take your photos </ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                      We've provided a helpful video to guide you in taking
                      photos of the damage accurately. To avoid any delays or
                      complications, please make sure that your images meet the
                      provided criteria when submitting your request.
                    </ion-card-content>
                  </ion-col>
                  <ion-col size-md="6" size-sm="8" size-xs="12">
                    <video controls>
                      <source :src="productVideoSrc" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card style="box-shadow: none">
              <ion-card-header style="padding-inline: 0">
                <ion-card-title class="ion-padding-bottom">
                  Step-by-step guide
                </ion-card-title>
              </ion-card-header>
              <img
                :src="
                  isMobile ? productBannerMobileSrc : productBannerDesktopSrc
                "
              />
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-end">
          <ion-col size-md="3" size-xs="12">
            <ion-button
              id="next-btn"
              expand="full"
              @click="nextPage(policyId)"
              :style="{ '--background': branding.color_primary }"
              >Proceed to upload photos</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
      <div
        id="contact-container"
        :style="{ 'background-color': branding.contact_container_color }"
      >
        <ion-grid :fixed="true" class="ion-padding">
          <ContactCard
            :getInTouchButton="true"
            :seeFAQ="true"
            title="Need further support?"
            description="Please check out our FAQs or get in touch with the team."
          />
        </ion-grid>
      </div>
      <FooterComponent />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from '@ionic/vue';
import BannerComponent from '@/components/BannerComponent.vue';
import SubmitRequestBreadcrumbs from '@/components/SubmitRequestBreadcrumbs.vue';
import ContactCard from '@/components/ContactCard.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import products from '../../composables/products';
import { useCustomerStore } from '../../store/customer';
import { useBrandingStore } from '../../store/branding';

const desktopBannerSrc = ref('');
const mobileBannerSrc = ref('');
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => {
  return screenWidth.value < 768;
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const route = useRoute();
const router = useRouter();
const customerStore = useCustomerStore();
const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
const policyId = computed(() => {
  return route.params.policyId;
});
const product = computed(() => {
  return customerStore.policies.find((policy) => policy.id === policyId.value)
    .product;
});
const relatedProduct = products.find((item) =>
  product.value.includes(item.name)
);
const productBannerDesktopSrc = ref(
  require(`../../../public/assets/gardxgroup/banners/product_banners/${relatedProduct.desktopBanner}.png`)
);
const productBannerMobileSrc = ref(
  require(`../../../public/assets/gardxgroup/banners/product_banners/${relatedProduct.mobileBanner}.jpg`)
);
const productVideoSrc = ref(
  require(`../../../public/assets/gardxgroup/videos/${relatedProduct.video}.mp4`)
);

desktopBannerSrc.value = brandingStore.brand.desktop_banner_6;
mobileBannerSrc.value = brandingStore.brand.mobile_banner_6;

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

function nextPage(policyId) {
  router.push({ name: 'submit-photos', params: { policyId } });
}
</script>

<style lang="scss" scoped>
.ion-page {
  margin-top: 125px;
}

@media only screen and (max-width: 768px) {
  .ion-page {
    margin-top: 42px;
  }
}

.banner-container {
  position: relative;
}

ion-card {
  margin: 0 auto;
}

video {
  width: 100%;
  height: 240px;
}
</style>
